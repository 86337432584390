import jsPDF from 'jspdf';
import printMixin from '@/mixin/printMixin';
import moment from 'moment/moment';

export default {
  methods: {
    printLabResult({
      staff_request,
      doctor = '',
      guarantorType = '',
      timeOrder = '',
      timeFinish = '',
      name = '',
      address = '',
      gender = '',
      birthDateAge = '',
      noLabRM = '',
      testItem = [],
      clinicalNotes = '',
      recommendation = '',
      timestamps = new Date(),
    } = {}) {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'in',
        format: 'a4',
      });
      const topMargin = 2.4;
      const leftMargin = 0.5;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1.3;

      doc.setFontSize(11);
      doc.setFont('Helvetica', 'bold');

      doc.setFontSize(14);
      doc.setFont('Helvetica', 'bold');
      this.pdfTextPrint(doc, 'Hasil Pemeriksaan Laboratorium', 2);

      let startY = topMargin;

      doc.autoTable({
        body: [
          ['Dokter Pengirim', ':', staff_request],
          ['Tipe Penjamin', ':', `${guarantorType}`],
          ['Tgl. Periksa', ':', `${timeOrder}`],
          ['Tgl. Selesai', ':', `${timeFinish}`],
        ],
        startY: startY,
        styles: {
          align: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 1.4 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 2.5 },
        },
        margin: {
          left: leftMargin,
        },
        theme: 'plain',
      });

      doc.autoTable({
        body: [
          [
            'Nama Pasien',
            ':',
            {
              content: `${name}`,
              styles: { fontStyle: 'bold' },
            },
          ],
          ['Alamat', ':', `${address}`],
          ['Jenis Kelamin', ':', `${gender}`],
          ['Tgl. Lahir/Usia', ':', `${birthDateAge}`],
          [
            'No. Lab/No.RM',
            ':',
            {
              content: `${noLabRM}`,
              styles: { fontStyle: 'bold' },
            },
          ],
        ],
        startY: startY,
        styles: {
          align: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 1.3 },
          1: { cellWidth: 0.2 },
          2: { cellWidth: 1.5 },
        },
        margin: {
          left: leftMargin + 4,
        },
        theme: 'plain',
      });

      startY = doc.autoTable.previous.finalY + 0.5;

      let lastDidDrawPageData = null;
      doc.autoTable({
        head: [
          [
            'PEMERIKSAAN',
            {
              content: 'HASIL',
              styles: { halign: 'center' },
            },
            {
              content: 'UNIT',
              styles: { halign: 'center' },
            },
            {
              content: 'NILAI NORMAL',
              styles: { halign: 'center' },
            },
            {
              content: 'METODE',
              styles: { halign: 'center' },
            },
          ],
        ],
        body: testItem?.reduce((acc, curr) => {
          acc.push([
            {
              content: curr.name,
              styles: { fontStyle: 'bold', textColor: 'black' },
            },
          ]);
          curr.result.forEach(itemResult => {
            acc.push([
              {
                content: itemResult.name,
                styles: {
                  fontStyle: 'bold',
                  cellPadding: {
                    top: 0.05,
                    left: 0.35,
                  },
                  textColor: itemResult.isNormal === false ? 'red' : 'black',
                },
              },
              {
                content: itemResult.result,
                styles: {
                  fontStyle: 'bold',
                  halign: 'center',
                  textColor: itemResult.isNormal === false ? 'red' : 'black',
                },
              },
              {
                content: itemResult.unit,
                styles: {
                  fontStyle: 'bold',
                  halign: 'center',
                  textColor: itemResult.isNormal === false ? 'red' : 'black',
                },
              },
              {
                content:
                  itemResult?.normal === undefined ? '' : itemResult?.normal,
                styles: {
                  fontStyle: 'bold',
                  halign: 'center',
                  textColor: itemResult.isNormal === false ? 'red' : 'black',
                },
              },
              {
                content: itemResult.method,
                styles: {
                  fontStyle: 'bold',
                  halign: 'center',
                  textColor: itemResult.isNormal === false ? 'red' : 'black',
                },
              },
            ]);
          });
          return acc;
        }, []),
        startY: startY,
        didDrawPage: d => {
          lastDidDrawPageData = d;
        },
        margin: {
          top: addLine(2),
        },
        theme: 'striped',
      });

      lastDidDrawPageData.cursor.y >= 9.7 && doc.addPage();
      startY =
        lastDidDrawPageData.cursor.y >= 9.7
          ? 2
          : doc.autoTable.previous.finalY + 0.5;

      doc.autoTable({
        body: [
          [
            {
              content: 'Catatan / Kesan : ',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            {
              content: `${clinicalNotes}`,
              styles: { halign: 'justify' },
            },
          ],
        ],
        startY: startY,
        styles: {
          align: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 7.13 },
        },
        margin: {
          left: leftMargin,
          top: addLine(2),
        },
        theme: 'plain',
      });

      startY = doc.autoTable.previous.finalY + 0.5;

      doc.autoTable({
        body: [
          [
            {
              content: 'Rekomendasi : ',
              styles: { fontStyle: 'bold' },
            },
          ],
          [
            {
              content: `${recommendation}`,
              styles: { halign: 'justify' },
            },
          ],
        ],
        startY: startY,
        styles: {
          align: 'left',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 7.13 },
        },
        margin: {
          left: leftMargin,
          top: addLine(2),
        },
        theme: 'plain',
      });

      startY = doc.autoTable.previous.finalY + 0.5;

      doc.autoTable({
        body: [
          [
            {
              content: `${
                this.$store.getters.userLoggedIn.clinic.clinicRegion
              }, ${moment(timestamps)
                .locale('id')
                .format('DD MMMM YYYY')}`,
              styles: { halign: 'center' },
            },
          ],
          [
            {
              content: 'Petugas Laboratorium',
              styles: { halign: 'center' },
            },
          ],
          [{ content: '', styles: { halign: 'center', minCellHeight: 0.7 } }],
          [
            {
              content: `${doctor}`,
              styles: { halign: 'center' },
            },
          ],
        ],
        startY: startY,
        styles: {
          align: 'center',
          fontSize: 11,
        },
        columnStyles: {
          0: { cellWidth: 3 },
          1: { cellWidth: 3 },
          2: { cellWidth: 3 },
          3: { cellWidth: 4 },
        },
        margin: {
          left: leftMargin + 4,
          top: addLine(2),
        },
        theme: 'plain',
      });
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Header
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
        );
      }
      return doc;
    },
  },
};
