import Constant from '@/const';
import axios from 'axios';

const postUpdateLetterStatus = async payload => {
  try {
    const response = await axios.post(
      Constant.apiUrl.concat('/patient/registration/letter'),
      payload,
    );
    return response;
  } catch (error) {
    this.showErrorAxios(error);
  }
};

export default postUpdateLetterStatus;
