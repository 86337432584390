import {
  getPatientQueue,
  getPublicQueue,
  startQueue,
  postModifyQueue,
} from '../../../fetchApi/PatientQueue';
import alertMixin from '../../../mixin/alertMixin';

const state = {
  queue: '',
  isHasSchedule: '',
  isHasQueue: '',
  publicQueue: [],
  isLoading: false,
  remainingCount: '',
  isMissedCount: '',
  room: '',
  total: '',
  isStarted: '',
  staff: '',
  unit: '',
  missedPatients: '',
  missedPatientsForPublicView: '',
};

const initialState = {
  queue: '',
  isHasSchedule: '',
  isHasQueue: '',
  publicQueue: [],
  isLoading: false,
  remainingCount: '',
  isMissedCount: '',
  room: '',
  total: '',
  isStarted: '',
  staff: '',
  unit: '',
  missedPatients: '',
  missedPatientsForPublicView: '',
};

const getters = {
  getQueue: state => state.queue,
  getIsHasSchedule: state => state.isHasSchedule,
  getIsHasQueue: state => state.isHasQueue,
  getPublicQueue: state => state.publicQueue,
  getIsLoading: state => state.isLoading,
  getRemainingCount: state => state.remainingCount,
  getIsMissedCount: state => state.isMissedCount,
  getRoom: state => state.room,
  getTotal: state => state.total,
  getIsStarted: state => state.isStarted,
  getUnit: state => state.unit,
  getStaff: state => state.staff,
  getMissedPatients: state => state.missedPatients,
  getMissedPatientsForPublicView: state => state.missedPatientsForPublicView,
};

const mutations = {
  clearState(state) {
    Object.assign(state, initialState);
  },
  setQueue(state, payload) {
    state.queue = payload;
  },
  setIsHasSchedule(state, payload) {
    state.isHasSchedule = payload;
  },
  setIsHasQueue(state, payload) {
    state.isHasQueue = payload;
  },
  setPublicQueue(state, payload) {
    state.publicQueue = payload;
  },
  setIsLoading(state, payload) {
    state.isLoading = payload;
  },
  setRemainingCount(state, payload) {
    state.remainingCount = payload;
  },
  setIsMissedCount(state, payload) {
    state.isMissedCount = payload;
  },
  setRoom(state, payload) {
    state.room = payload;
  },
  setTotal(state, payload) {
    state.total = payload;
  },
  setIsStarted(state, payload) {
    state.isStarted = payload;
  },
  setStaff(state, payload) {
    state.staff = payload;
  },
  setUnit(state, payload) {
    state.unit = payload;
  },
  setMissedPatients(state, payload) {
    state.missedPatients = payload;
  },
  setMissedPatientsForPublicView(state, payload) {
    state.missedPatientsForPublicView = payload;
  },
};

const actions = {
  async resolveGetPatientQueue({ commit }, options) {
    try {
      commit('setIsLoading', true);
      const response = await getPatientQueue({
        payload: options.payload,
      });
      const [data] = response.data.data;
      commit('setQueue', data?.queueNormal || []);
      commit('setIsHasQueue', data?.isHasQueue || false);
      commit('setIsHasSchedule', data?.isHasSchedule || false);
      commit('setMissedPatients', data?.queueMissed || []);
      commit('setRemainingCount', data?.remainingCount || 0);
      commit('setIsMissedCount', data?.isMissedCount || 0);

      commit('setTotal', data?.total || 0);
      commit('setRoom', data?.room || '-');
      commit('setIsStarted', data?.isStarted || false);
      commit('setStaff', data?.id_staff?.detail[0]?.name || '-');
      commit('setUnit', data?.id_unit?.name || '-');
    } catch (error) {
      alertMixin.methods.showErrorAxios(error);
      commit('clearState');
    } finally {
      commit('setIsLoading', false);
    }
  },
  async resolveModifyQueue({ commit }, payload) {
    try {
      commit('setIsLoading', true);
      await postModifyQueue({ payload });
    } catch (error) {
      alertMixin.methods.showErrorAxios(error);
    } finally {
      commit('setIsLoading', false);
    }
  },
  async resolveStartQueue(_, payload) {
    try {
      await startQueue(payload);
    } catch (error) {
      alertMixin.methods.showErrorAxios(error);
    }
  },
  async resolveGetPublicQueue({ commit }) {
    try {
      const response = await getPublicQueue();
      let { data } = response.data;
      if (!data) return;

      data = data.map(v => {
        return {
          ...v,
          isNormalFont: v.queueNormal[0].queue_code.length <= 7 ? true : false,
        };
      });
      commit(
        'setPublicQueue',
        data?.filter(item => item.queueNormal?.length > 0),
      );
      let missedPatientsList = [];
      data.forEach(item => {
        if (item.queueMissed && item.queueMissed?.length > 0) {
          missedPatientsList.push({
            poly: item.id_unit?.name,
            doctor: item.id_staff?.detail?.[0]?.name,
            queueCodeList: item.queueMissed?.map(i => i.queue_code),
          });
        }
      });
      commit('setMissedPatientsForPublicView', missedPatientsList);
    } catch (error) {
      alertMixin.methods.showErrorAxios(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
