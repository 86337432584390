<template>
  <div class="inventory">
    <v-toolbar flat outlined>
      <v-container fluid>
        <v-row align="center">
          <div style="display: grid" class="text-left">
            <v-toolbar-title class="font-weight-bold float-left">
              Penerimaan Barang
            </v-toolbar-title>
            <div class="caption green--text float-left">
              {{ infoText }}
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn @click="filterBtn()" outlined>
            <span class="text-capitalize">FILTERS</span>
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
          <v-btn class="mr-2" color="grey" icon text @click="refresh()">
            <v-icon :class="{ refresh: isRefresh }">mdi-autorenew</v-icon>
          </v-btn>
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            v-model="table.search"
          ></v-text-field>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <div class="mt-4" v-if="filter">
        <v-row
          no-gutters
          align="center"
          justify="center"
          class="filter-menu"
          :class="{
            range: filterType.includes('Rentang'),
            per: filterType.includes('Per'),
          }"
        >
          <v-select
            :items="resource.filterType"
            dense
            v-model="filterType"
            outlined
            hide-details="false"
            @change="handleFilterTypeChange"
          ></v-select>
          <div :class="{ 'd-none': filterType === 'Semua Waktu' }">
            <!-- Per Tanggal -->
            <Datepicker
              v-if="filterType === 'Per Tanggal'"
              :clickCloseContent="false"
              :max="today"
              :show="datePicker"
              :date="date"
              :isClearable="false"
              placeholder="Per Tanggal"
              :showIconCalendar="true"
              @date-change="handleDateChange"
            />
            <!-- Range Tanggal -->
            <div
              v-if="filterType === 'Rentang Tanggal'"
              class="d-flex align-center"
            >
              <Datepicker
                :max="today"
                :show="datePickerzz.rangeDate"
                :date="dateRange.start"
                :isClearable="false"
                @date-change="handleDateChange($event, true)"
                :showIconCalendar="true"
                placeholder="Tanggal Awal"
              />
              <span class="mx-2">s.d</span>
              <!-- @date-change="handleDateChange" -->
              <Datepicker
                :max="today"
                :min="dateRange.start"
                :show="datePickerzz.rangeDate"
                :date="dateRange.end"
                :isClearable="false"
                :showIconCalendar="true"
                @date-change="handleDateChange($event, false)"
                placeholder="Tanggal Akhir"
              />
            </div>
            <!-- Per Bulan -->
            <Datepicker
              v-if="filterType === 'Per Bulan'"
              :show="datePickerzz.perMonth"
              :date="month.raw"
              :max="today"
              :isClearable="false"
              :typeDate="'month'"
              :dateFormat="'MMMM YYYY'"
              :showIconCalendar="true"
              @date-change="handleMonthChange"
              placeholder="Per Bulan"
            />
            <!-- Rentang Bulan -->
            <div
              v-if="filterType === 'Rentang Bulan'"
              class="d-flex align-center"
            >
              <Datepicker
                :show="datePickerzz.rangeMonth"
                :date="monthRange.start"
                :max="today"
                :isClearable="false"
                :typeDate="'month'"
                :dateFormat="'MMMM YYYY'"
                @date-change="handleMonthChange($event, true)"
                :showIconCalendar="true"
                placeholder="Bulan Awal"
              />
              <span class="mx-2">s.d</span>
              <Datepicker
                :show="datePickerzz.rangeMonth"
                :date="monthRange.end"
                :isClearable="false"
                :min="monthRange.start"
                :max="today"
                :typeDate="'month'"
                :dateFormat="'MMMM YYYY'"
                :showIconCalendar="true"
                @date-change="handleMonthChange($event, false)"
                placeholder="Bulan Akhir"
              />
            </div>
          </div>
          <v-select
            item-text="name"
            item-value="id"
            :items="supplierName"
            dense
            return-object
            v-model="filterSupplier"
            placeholder="Nama Supplier"
            outlined
            hide-details="false"
            clearable
            @change="onChangeSupplier"
          ></v-select>
          <v-btn
            class="text-none"
            color="primary"
            tile
            outlined
            depressed
            :disabled="!exportAvailable"
            style="border-radius: 4px;"
            height="40"
            @click="exportItemReceipt"
            :loading="this.loading.export"
            ><v-icon size="20" class="mr-2">mdi-download</v-icon>Export
            Data</v-btn
          >
        </v-row>
      </div>

      <v-container fluid>
        <v-col class="pa-0">
          <v-data-table
            class="flex-grow-1"
            :headers="table.headers"
            :items="table.dataItem"
            :items-per-page="itemsPerRow"
            hide-default-footer
            :loading="isLoading"
          >
            <template v-slot:item="{ item, index }">
              <tr class="row-datatable" :key="index">
                <td class="text-sm-left">
                  {{ (table.pagination.page - 1) * itemsPerRow + index + 1 }}
                </td>
                <td class="text-sm-left">
                  {{ item.receiptDate }}
                </td>
                <td class="text-sm-left">
                  {{ item.dueDate }}
                </td>
                <td class="text-sm-left">
                  {{ item.invoiceNumber }}
                </td>
                <td class="text-sm-left">
                  {{ item.supplierName }}
                </td>
                <td class="text-sm-left text-capitalize">
                  {{ item.paymentMethod }}
                </td>
                <td class="text-sm-left">{{ item.user }}</td>
                <td class="text-sm-left">
                  <v-icon small class="mr-2" @click="onClickFooter(1, item)">
                    mdi-dots-vertical
                  </v-icon>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-container>
    </v-main>

    <v-footer color="white" class="py-0">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-if="table?.dataItem?.length"
            v-model="table.pagination.page"
            :length="itemPages"
            total-visible="7"
            @input="refreshAndAutoUpdate()"
          ></v-pagination>
          <v-spacer></v-spacer>

          <template>
            <v-dialog v-model="dialog" max-width="1200px" fullscreen>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  fab
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="onClickFooter(0, '')"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <InputItemReceipt
                v-if="selectedForm == 0"
                @change-form="changeForm"
                @close-form="closeForm"
              />
              <DetailItemReceipt
                v-if="selectedForm == 1 && dialog"
                :selected-data="selectedData"
                @change-form="changeForm"
                @close-form="closeForm"
              />
            </v-dialog>
          </template>
        </v-row>
        <v-snackbar
          content-class="d-flex justify-space-between"
          v-model="snackbar.show"
          :color="snackbar.color"
          bottom
        >
          {{ snackbar.text }}
          <v-icon small color="white" @click="snackbar.show = false"
            >mdi-close</v-icon
          >
        </v-snackbar>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import InputItemReceipt from './ItemReceiptComponent/InputItemReceipt';
import DetailItemReceipt from './ItemReceiptComponent/DetailItemReceipt';
import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';
import Constant from '@/const';
import jwtMixin from '@/mixin/jwtMixin';
import moment from 'moment-timezone';

const axios = require('axios');
const _ = require('lodash');
import { getItemReceiptExport } from '@/fetchApi/Inventory';

export default {
  name: 'ItemReceipt',
  mixins: [jwtMixin],
  components: {
    Datepicker,
    InputItemReceipt,
    DetailItemReceipt,
  },
  data() {
    return {
      isLoading: false,
      loading: {
        export: false,
      },
      exportAvailable: false,
      datePicker: false,
      filterDate: '',
      date: moment().format('YYYY-MM-DD'),
      filterType: 'Per Tanggal',
      filter: false,
      dialog: false,
      filterSupplier: '',
      isRefresh: false,
      supplierName: [],
      editedItem: {
        noQueue: 0,
        billDate: '',
        sp: '',
        supplierName: '',
        user: '',
      },
      snackbar: {
        color: '',
        show: false,
        text: '',
      },
      itemPages: 0,
      infoText: '',
      show: false,
      today: moment().format(),
      datePickerzz: {
        perDate: false,
        rangeDate: false,
        perMonth: false,
        rangeMonth: false,
      },
      monthRange: {
        start: '',
        end: '',
      },
      dateRange: {
        start: '',
        end: '',
      },
      month: {
        raw: '',
        formatted: '',
      },
      menu: {
        month: false,
        endDate: false,
        startDate: false,
      },
      table: {
        search: '',
        pagination: {
          descending: true,
          page: 1,
          // rowsPerPage: 7,
          totalItems: null,
        },
        dataItem: [],
        headers: [
          { text: 'No.', value: 'noQueue' },
          { text: 'Tanggal penerimaan', value: 'receiptDate' },
          { text: 'Tanggal Tempo', value: 'dueDate' },
          { text: 'Nomor Faktur', value: 'invoiceNumber' },
          { text: 'Nama Supplier', value: 'supplierName' },
          { text: 'Metode Pembayaran', value: 'paymentMethod' },
          { text: 'Petugas Penerima', value: 'user' },
          { text: '', value: 'actions', sortable: false },
        ],
      },
      selectedForm: null,
      selectedData: null,
      resource: {
        filterType: [
          'Semua Waktu',
          'Per Tanggal',
          'Rentang Tanggal',
          'Per Bulan',
          'Rentang Bulan',
        ],
      },
    };
  },

  watch: {
    'table.search'() {
      this.searchData(this);
    },
    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null;
      }
    },
    date(val) {
      if (val) {
        this.dateFormat = this.formatDate(this.date);
      } else if (val === null) {
        this.getAllItem();
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
      this.getSupplier();
    });
  },
  computed: {
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    dateFilled() {
      let isFilled = false;
      const filter = this.resource.filterType;
      if (this.filterType === filter[0]) {
        isFilled = true;
      } else if (this.filterType === filter[1]) {
        isFilled = this.date !== '';
      } else if (this.filterType === filter[2]) {
        isFilled = this.dateRange.start !== '' && this.dateRange.end !== '';
      } else if (this.filterType === filter[3]) {
        isFilled = this.month.raw !== '';
      } else if (this.filterType === filter[4]) {
        isFilled = this.monthRange.start !== '' && this.monthRange.end !== '';
      }
      return isFilled;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.table.pagination.page = 1;
      v.refreshAndAutoUpdate();
    }, 250),
    onChangeSupplier(value) {
      if (value === null || value === undefined || value === '') {
        if (!this.dateFilled) return;
      }
      this.getAllItem();
    },
    clearDate() {
      this.date = '';
      this.dateRange = {
        start: '',
        end: '',
      };
      this.monthRange = {
        start: '',
        end: '',
      };
      this.month = {
        raw: '',
        formatted: '',
      };

      if (this.filterType === 'Semua Waktu') this.reloadPage();
    },
    reloadPage() {
      this.table.pagination.page = 1;
      this.refreshAndAutoUpdate();
    },
    handleFilterTypeChange() {
      this.clearDate();
      if (this.filterType === 'Semua Waktu') return;

      this.exportAvailable = false;
      // this.getAllItem();
    },
    handleDateChange(updatedDate, isStart = true) {
      if (this.filterType === 'Rentang Tanggal') {
        const key = isStart ? 'start' : 'end';
        this.dateRange[key] = updatedDate;
      } else {
        this.date = updatedDate;
      }
      this.reloadPage();
    },
    handleMonthChange(updatedDate, isStart = true) {
      if (this.filterType === 'Rentang Bulan') {
        const key = isStart ? 'start' : 'end';
        this.monthRange[key] = updatedDate;
      } else {
        this.month.raw = updatedDate;
      }
      this.reloadPage();
    },
    filterBtn() {
      if (this.filter === false) {
        this.filter = true;
      } else {
        this.filter = false;
      }
    },
    refresh() {
      this.isRefresh = true;
      this.getAllItem();
    },
    getSupplier() {
      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/master/supplier')).then(response => {
          const getSupplier = response.data.data;
          this.supplierName = getSupplier.map(supplier => {
            return {
              id: supplier._id,
              name: supplier.name,
            };
          });
        });
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    onClickFooter(index, receipt) {
      this.changeSelectedItem(receipt);

      switch (index) {
        default:
          this.changeForm(index);
          break;
      }
      this.dialog = true;
    },
    changeSelectedItem(receipt) {
      this.selectedData = receipt;
    },
    changeForm(item) {
      this.selectedForm = item;
    },
    closeForm() {
      this.dialog = false;
      this.getAllItem();
    },
    setDateFilter() {
      const filter = this.resource.filterType;
      let startDate = '';
      let endDate = '';
      let isMonth = false;
      let isRange = false;
      switch (this.filterType) {
        case filter[1]:
          startDate = this.date;
          endDate = this.date;
          break;
        case filter[2]:
          isRange = true;
          startDate = this.dateRange.start;
          endDate = this.dateRange.end;
          break;
        case filter[3]:
          isMonth = true;
          startDate = moment(this.month.raw)
            .startOf('month')
            .format('YYYY-MM-DD');
          endDate = moment(this.month.raw)
            .endOf('month')
            .format('YYYY-MM-DD');
          break;
        case filter[4]:
          isMonth = true;
          isRange = true;
          startDate = moment(this.monthRange.start)
            .startOf('month')
            .format('YYYY-MM-DD');
          endDate = moment(this.monthRange.end)
            .endOf('month')
            .format('YYYY-MM-DD');
          break;
      }

      return { startDate, endDate, isMonth, isRange };
    },
    async exportItemReceipt() {
      try {
        const supplier = this.filterSupplier ? this.filterSupplier.name : '';
        const { startDate, endDate } = this.setDateFilter();

        this.loading.export = true;
        const params = {
          startDate,
          endDate,
          supplier,
        };

        const res = await getItemReceiptExport(params);
        if (res.status === 200) {
          const { url } = res.config;
          window.open(`${url}`);
          this.snackbar = {
            show: true,
            color: 'success',
            text: 'Laporan berhasil diekspor',
          };
        }
      } catch (e) {
        this.snackbar = {
          show: true,
          color: 'error',
          text: 'Ekspor gagal, silakan coba lagi',
        };
      } finally {
        this.loading.export = false;
      }
    },
    setInfoText({ startDate, endDate, isMonth, isRange }) {
      const supplierText = this.filterSupplier?.name
        ? this.filterSupplier.name
        : 'Semua Supplier';

      const dateText = isMonth
        ? isRange
          ? `${moment(startDate).format('MMMM YYYY')} s.d ${moment(
              endDate,
            ).format('MMMM YYYY')}`
          : `${moment(startDate).format('MMMM YYYY')}`
        : isRange
        ? `${moment(startDate).format('DD-MM-YYYY')} s.d ${moment(
            endDate,
          ).format('DD-MM-YYYY')}`
        : moment(startDate).format('DD-MM-YYYY');

      if (this.filterType === 'Semua Waktu') {
        this.infoText = `Penerimaan barang dari ${supplierText}`;
      } else {
        this.infoText = `Penerimaan barang dari ${supplierText} pada tanggal ${dateText}`;
      }
    },
    getAllItem() {
      const {
        startDate,
        endDate,
        isRange = false,
        isMonth = false,
      } = this.setDateFilter();

      if ((startDate && endDate === '') || endDate === 'Invalid date') {
        this.exportAvailable = false;
        return;
      }

      this.setInfoText({ startDate, endDate, isRange, isMonth });
      this.isLoading = true;
      const itemCount = this.itemsPerRow;
      const supplier = this.filterSupplier ? this.filterSupplier.name : '';
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl
              .concat(
                `/supply/itemlist?page=${
                  this.table.pagination.page
                }&itemCount=${itemCount}&search=${this.table.search ||
                  ''}&supplier_name=${supplier}`,
              )
              .concat(`&startDate=${startDate}`)
              .concat(`&endDate=${endDate}`),
          )
          .then(response => {
            if (response.data.data.length === 0) {
              this.isLoading = false;
              this.table.dataItem = [];
            }
            const { data } = response.data;
            const { length: dataLength } = response.data;
            this.itemPages = Math.ceil(dataLength / this.itemsPerRow);
            this.table.dataItem = data
              .sort(
                (a, b) =>
                  moment(b.timestamps.created_at) -
                  moment(a.timestamps.created_at),
              )
              .map(dataItem => {
                return {
                  id: dataItem._id.toString(),
                  dueDate: dataItem.bill_due,
                  receiptDate: moment(dataItem.timestamps.created_at).format(
                    'DD/MM/YYYY',
                  ),
                  invoiceNumber: dataItem.invoice_number,
                  paymentMethod: dataItem.payment_type,
                  pendingReason: dataItem.pending_reason,
                  supplierName: dataItem.supplier
                    ? dataItem.supplier.name
                    : '-',
                  idSupplier: dataItem.id_supplier ? dataItem.id_supplier : '-',
                  user: dataItem.staff_name,
                  deliveryFee: dataItem.cost ? dataItem.cost.shipping_cost : '',
                  total: dataItem.cost ? dataItem.cost.total_cost : '',
                  stampCost: dataItem.cost ? dataItem.cost.stamp_cost : '',
                  receiptNumber: dataItem.receive_number,
                  item: dataItem.item,
                  orderDate: dataItem.supply_request
                    ? moment(dataItem.supply_request.order_date).format(
                        'DD/MM/YYYY',
                      )
                    : '-',
                  orderNumber: dataItem.supply_request
                    ? dataItem.supply_request.order_number
                    : '-',
                };
              });
          })
          .catch(() => {
            this.exportAvailable = false;
            this.table.dataItem = [];
            this.itemPages = 0;
          })
          .finally(() => {
            if (this.table.dataItem.length) this.exportAvailable = true;
            else this.exportAvailable = false;

            this.isLoading = false;
            this.isRefresh = false;
          });
      });
    },
    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllItem();
      // this.interval = setInterval(this.getAllItem, 60000);
    },
  },
  beforeDestroy() {
    this.interval = clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
.filter-menu {
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 15%) 13%;
  &.per {
    grid-template-columns: repeat(3, 15%) 13%;
  }
  &.range {
    grid-template-columns: 15% 35% 15% 13%;
  }
}
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.input {
  height: 33px;
}
</style>
