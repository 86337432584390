import { getAllergiesByIdEmr } from '../../../fetchApi/EMR';
import getPatientRegistrationById from '../../../fetchApi/Registration/getRegistrationPatientById';
import moment from 'moment';

const state = {
  allergies: {
    air: [],
    food: [],
    drug: [],
    other: [],
  },
  patient: {
    rmNumber: '',
    name: '',
    birthDate: '',
    age: '',
    gender: '',
    unit: '',
    assurance: '',
    staff: '',
  },
};

const getters = {
  getAllergies: state => state.allergies,
  getPatient: state => state.patient,
};

const mutations = {
  setAllergies(state, payload) {
    state.allergies = payload;
  },
  setPatient(state, payload) {
    state.patient = payload;
  },
  clearStateHeader() {
    state.allergies = {
      air: [],
      food: [],
      drug: [],
      other: [],
    };
    state.patient = {
      rmNumber: '',
      name: '',
      birthDate: '',
      age: '',
      gender: '',
      unit: '',
      assurance: '',
      staff: '',
    };
  },
};

const actions = {
  async resolveGetAllergiesPatient({ commit }, id_emr) {
    try {
      const res = await getAllergiesByIdEmr(id_emr);
      const { data } = res.data;
      const payload = {
        air: data.allergy_air,
        food: data.allergy_food,
        drug: data.allergy_drug,
        other: data.allergy_history,
      };
      commit('setAllergies', { ...payload });
    } catch (error) {
      const err = error;
      return err;
    }
  },
  async resolveGetPatientData({ commit }, id_regist) {
    try {
      const res = await getPatientRegistrationById(id_regist);
      const { social, medical_record } = res;
      const data = {
        rmNumber: medical_record.medical_record_number,
        name: social.name,
        birthDate: moment(social.birth.date)
          .locale('id')
          .format('L'),
        age: social.age,
        gender: social.gender ? 'L' : 'P',
        unit: res.registration?.id_unit.name,
        assurance: res.assurance?.type,
        doctor: res.registration.id_staff.name,
      };
      commit('setPatient', { ...data });
    } catch (error) {
      throw new Error(error);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
