<template>
  <v-card class="main-card py-5">
    <v-btn
      @click="$emit('close-form')"
      style="position: absolute; right: 1vw"
      fab
      x-small
      text
      ><v-icon>mdi-close</v-icon></v-btn
    >
    <h3 class="pl-5 text-start">Bridging PCare</h3>
    <p class="pl-5 text-start ma-0 pa-0 button" style="color: #9e9e9e;">
      Silakan pilih salah satu opsi sebelum menyimpan
    </p>
    <div
      style="width: 100%; height: 1px; background-color: #e0e0e0; margin-top: .8vh;"
    ></div>
    <v-radio-group class="px-5" v-model="isSendToPcare">
      <v-radio
        :class="
          `radio-container d-flex align-start ${isSendToPcare ? 'active' : ''}`
        "
        :value="true"
      >
        <template v-slot:label>
          <div class="selection">
            <p :class="`${isSendToPcare ? 'primary--text' : ''}`">
              <strong>Simpan dan kirim ke PCare</strong>
            </p>
            <p class="ma-0 pa-0">
              Pilih ini jika Anda tidak perlu menginputkan data odontogram via
              PCare.
            </p>
            <p class="ma-0 pa-0">
              Setelah berhasil terkirim, Anda tidak dapat menginputkan data lagi
            </p>
          </div>
        </template>
      </v-radio>
      <v-radio
        :class="
          `radio-container d-flex align-start ${
            isSendToPcare === false ? 'active' : ''
          }`
        "
        :value="false"
      >
        <template v-slot:label>
          <div class="selection">
            <p :class="`${isSendToPcare === false ? 'primary--text' : ''}`">
              <strong>Simpan dan jangan kirim ke PCare</strong>
            </p>
            <p class="ma-0 pa-0">
              Pilih ini jika Anda perlu menginputkan data odontogram via PCare
              secara manual
            </p>
          </div>
        </template>
      </v-radio>
    </v-radio-group>
    <div class="px-4 mb-5">
      <div class="additional-info pa-3 d-flex align-start">
        <v-icon class="mr-3" color="primary">mdi-information</v-icon>
        <p class="ma-0 pa-0">
          PCare saat ini tidak memiliki fasilitas untuk menerima data odontogram
          dari sistem kami. Jika pasien akan dirujuk lanjut, pastikan Anda
          memilih untuk tidak mengirim odontogram dan menginputkannya secara
          manual di PCare
        </p>
      </div>
    </div>
    <div class="pr-4" style="width: 100%; justify-content: end; display: flex;">
      <v-btn
        @click="$emit('close-form')"
        color="primary"
        outlined
        class="text-capitalize action-button mr-2"
        small
      >
        Batal
      </v-btn>
      <v-btn
        :disabled="isSendToPcare === ''"
        @click="$emit('save', false, isSendToPcare)"
        depressed
        color="primary"
        class="text-capitalize action-button"
        small
      >
        Simpan
      </v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      isSendToPcare: '',
    };
  },
};
</script>

<style lang="scss" scoped>
.main-card {
  position: relative;
  width: 50vw;

  .radio-container {
    border: 2px solid #ededed;
    padding: 1.5rem;
    border-radius: 10px;

    &.active {
      border: 2px solid #1976d2;
      background-color: #f0f8fe;
    }

    .selection {
      p {
        font-size: 1.1rem;
        margin-bottom: 7px !important;
      }
    }
  }

  .additional-info {
    background-color: #fafafa;
    border-radius: 10px;
    text-align: justify;
    color: #616161;
  }

  .action-button {
    padding: 1rem;
    letter-spacing: 0.001px;
    font-size: 1rem;
  }
}
</style>
