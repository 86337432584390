var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"main-card"},[_c('v-card-title',{staticClass:"justify-end"},[_c('h3',[_vm._v("Pembuatan Surat")])]),_c('v-tabs',{staticClass:"pl-5",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(
        _vm.isInpatient ||
          _vm.isIGD ||
          _vm.isGeneral ||
          _vm.isDental ||
          _vm.isKIA ||
          _vm.isVaccine ||
          _vm.isPhysioterapist ||
          _vm.isKB
      )?_c('v-tab',{key:"outpatient",staticClass:"text-none font-weight-bold",staticStyle:{"letter-spacing":"0.001px"}},[_c('span',{class:_vm.hasAlert('Surat Kontrol Rawat Jalan') ? 'alert' : ''},[_vm._v(" Surat Kontrol RJ ")])]):_vm._e(),(
        !_vm.isInpatient ||
          _vm.isIGD ||
          _vm.isGeneral ||
          _vm.isDental ||
          _vm.isKIA ||
          _vm.isVaccine ||
          _vm.isPhysioterapist ||
          _vm.isKB
      )?_c('v-tab',{key:"inpatient",staticClass:"text-none font-weight-bold",staticStyle:{"letter-spacing":"0.001px"}},[_c('span',{class:_vm.hasAlert('Surat Perintah Rawat Inap') ? 'alert' : ''},[_vm._v(" Surat Perintah RI ")])]):_vm._e(),(
        (_vm.isBpjs && _vm.isInpatient) ||
          (_vm.isBpjs && _vm.isIGD) ||
          (_vm.isBpjs && _vm.isGeneral) ||
          (_vm.isBpjs && _vm.isDental) ||
          (_vm.isBpjs && _vm.isKIA) ||
          (_vm.isBpjs && _vm.isVaccine) ||
          (_vm.isBpjs && _vm.isPhysioterapist) ||
          (_vm.isBpjs && _vm.isKB)
      )?_c('v-tab',{key:"hospitalPcare",staticClass:"text-none font-weight-bold",staticStyle:{"letter-spacing":"0.001px"}},[_c('span',{class:_vm.hasAlert('Surat Rujukan ke Rumah Sakit') ? 'alert' : ''},[_vm._v(" Surat Rujukan ke RS ")])]):_vm._e(),(
        (!_vm.isBpjs && _vm.isInpatient) ||
          (!_vm.isBpjs && _vm.isIGD) ||
          (!_vm.isBpjs && _vm.isGeneral) ||
          (!_vm.isBpjs && _vm.isDental) ||
          (!_vm.isBpjs && _vm.isKIA) ||
          (!_vm.isBpjs && _vm.isVaccine) ||
          (!_vm.isBpjs && _vm.isPhysioterapist) ||
          (!_vm.isBpjs && _vm.isKB)
      )?_c('v-tab',{key:"hospital",staticClass:"text-none font-weight-bold",staticStyle:{"letter-spacing":"0.001px"}},[_c('span',{class:_vm.hasAlert('Surat Rujukan ke Rumah Sakit') ? 'alert' : ''},[_vm._v(" Surat Rujukan ke RS ")])]):_vm._e(),(
        _vm.isInpatient ||
          _vm.isIGD ||
          _vm.isGeneral ||
          _vm.isDental ||
          _vm.isKIA ||
          _vm.isVaccine ||
          _vm.isPhysioterapist ||
          _vm.isKB
      )?_c('v-tab',{key:"mortality",staticClass:"text-none font-weight-bold",staticStyle:{"letter-spacing":"0.001px"}},[_c('span',{class:_vm.hasAlert('Surat Keterangan Kematian') ? 'alert' : ''},[_vm._v(" Surat Keterangan Kematian ")])]):_vm._e(),(
        _vm.isInpatient ||
          _vm.isIGD ||
          _vm.isGeneral ||
          _vm.isDental ||
          _vm.isKIA ||
          _vm.isVaccine ||
          _vm.isPhysioterapist ||
          _vm.isKB
      )?_c('v-tab',{key:"sick",staticClass:"text-none font-weight-bold",staticStyle:{"letter-spacing":"0.001px"}},[_c('span',{class:_vm.hasAlert('Surat Keterangan Sakit') ? 'alert' : ''},[_vm._v(" Surat Keterangan Sakit ")])]):_vm._e(),(
        !_vm.isInpatient ||
          _vm.isIGD ||
          _vm.isGeneral ||
          _vm.isDental ||
          _vm.isKIA ||
          _vm.isVaccine ||
          _vm.isPhysioterapist ||
          _vm.isKB
      )?_c('v-tab',{key:"healthy",staticClass:"text-none font-weight-bold",staticStyle:{"letter-spacing":"0.001px"}},[_c('span',{class:_vm.hasAlert('Surat Keterangan Dokter') ? 'alert' : ''},[_vm._v(" Surat Keterangan Dokter ")])]):_vm._e()],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[(
        _vm.isInpatient ||
          _vm.isIGD ||
          _vm.isGeneral ||
          _vm.isDental ||
          _vm.isKIA ||
          _vm.isVaccine ||
          _vm.isPhysioterapist ||
          _vm.isKB
      )?_c('v-tab-item',{key:"outpatient"},[_c('OutpatientWarrant',{ref:"formOutpatientWarrant",attrs:{"is-has-unprinted-letter":_vm.hasAlert('Surat Kontrol Rawat Jalan'),"patient-data":_vm.patientData},on:{"close-form":function($event){return _vm.$emit('close-form')},"get-letter-status":_vm.resolveGetStatusLetter}})],1):_vm._e(),(
        !_vm.isInpatient ||
          _vm.isIGD ||
          _vm.isGeneral ||
          _vm.isDental ||
          _vm.isKIA ||
          _vm.isVaccine ||
          _vm.isPhysioterapist ||
          _vm.isKB
      )?_c('v-tab-item',{key:"inpatient"},[_c('InpatientWarrant',{ref:"formInpatientWarrant",attrs:{"patient-data":_vm.patientData,"is-has-unprinted-letter":_vm.hasAlert('Surat Perintah Rawat Inap')},on:{"close-form":function($event){return _vm.$emit('close-form')},"get-letter-status":_vm.resolveGetStatusLetter}})],1):_vm._e(),(
        (_vm.isBpjs && _vm.isInpatient) ||
          (_vm.isBpjs && _vm.isIGD) ||
          (_vm.isBpjs && _vm.isGeneral) ||
          (_vm.isBpjs && _vm.isDental) ||
          (_vm.isBpjs && _vm.isKIA) ||
          (_vm.isBpjs && _vm.isVaccine) ||
          (_vm.isBpjs && _vm.isPhysioterapist) ||
          (_vm.isBpjs && _vm.isKB)
      )?_c('v-tab-item',{key:"hospitalPcare"},[_c('HospitalPcare',{ref:"formHospitalPcare",attrs:{"patient-data":_vm.patientData,"is-has-unprinted-letter":_vm.hasAlert('Surat Rujukan ke Rumah Sakit')},on:{"close-form":function($event){return _vm.$emit('close-form')},"get-letter-status":_vm.resolveGetStatusLetter}})],1):_vm._e(),(
        (!_vm.isBpjs && _vm.isInpatient) ||
          (!_vm.isBpjs && _vm.isIGD) ||
          (!_vm.isBpjs && _vm.isGeneral) ||
          (!_vm.isBpjs && _vm.isDental) ||
          (!_vm.isBpjs && _vm.isKIA) ||
          (!_vm.isBpjs && _vm.isVaccine) ||
          (!_vm.isBpjs && _vm.isPhysioterapist) ||
          (!_vm.isBpjs && _vm.isKB)
      )?_c('v-tab-item',{key:"hospital"},[_c('Hospital',{ref:"formHospital",attrs:{"patient-data":_vm.patientData,"is-has-unprinted-letter":_vm.hasAlert('Surat Rujukan ke Rumah Sakit')},on:{"close-form":function($event){return _vm.$emit('close-form')},"get-letter-status":_vm.resolveGetStatusLetter}})],1):_vm._e(),(
        _vm.isInpatient ||
          _vm.isIGD ||
          _vm.isGeneral ||
          _vm.isDental ||
          _vm.isKIA ||
          _vm.isVaccine ||
          _vm.isPhysioterapist ||
          _vm.isKB
      )?_c('v-tab-item',{key:"mortality"},[_c('Mortality',{ref:"formMortality",attrs:{"patient-data":_vm.patientData,"is-has-unprinted-letter":_vm.hasAlert('Surat Keterangan Kematian')},on:{"close-form":function($event){return _vm.$emit('close-form')},"get-letter-status":_vm.resolveGetStatusLetter}})],1):_vm._e(),(
        _vm.isInpatient ||
          _vm.isIGD ||
          _vm.isGeneral ||
          _vm.isDental ||
          _vm.isKIA ||
          _vm.isVaccine ||
          _vm.isPhysioterapist ||
          _vm.isKB
      )?_c('v-tab-item',{key:"sick"},[_c('Sick',{ref:"formSick",attrs:{"is-has-unprinted-letter":_vm.hasAlert('Surat Keterangan Sakit'),"patient-data":_vm.patientData},on:{"get-letter-status":_vm.resolveGetStatusLetter,"close-form":function($event){return _vm.$emit('close-form')}}})],1):_vm._e(),(
        !_vm.isInpatient ||
          _vm.isIGD ||
          _vm.isGeneral ||
          _vm.isDental ||
          _vm.isKIA ||
          _vm.isVaccine ||
          _vm.isPhysioterapist ||
          _vm.isKB
      )?_c('v-tab-item',{key:"healthy"},[_c('Healthy',{ref:"formHealthy",attrs:{"patient-data":_vm.patientData,"is-has-unprinted-letter":_vm.hasAlert('Surat Keterangan Dokter')},on:{"close-form":function($event){return _vm.$emit('close-form')},"get-letter-status":_vm.resolveGetStatusLetter}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }