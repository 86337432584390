import Constant from '@/const';
import axios from 'axios';

const getItemReceiptExport = async params => {
  try {
    const { startDate, endDate, supplier } = params;
    const response = await axios.get(
      Constant.apiUrl
        .concat('/supply/itemlist/export?')
        .concat(`startDate=${startDate}&`)
        .concat(`endDate=${endDate}&`)
        .concat(`supplier=${supplier}&`),
    );
    return response;
  } catch (error) {
    const err = error;
    throw err;
  }
};

export default getItemReceiptExport;
