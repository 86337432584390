<template>
  <div>
    <h4
      id="doctor-assesment"
      class="title font-weight-bold primary--text text-left mb-4"
    >
      Assesmen & Planning
    </h4>
    <div v-for="(item, index) in form.diagnoses" :key="index">
      <v-row :key="index">
        <v-col cols="6" :class="isEmr ? 'grid-display-emr' : 'grid-display'">
          <label>{{ index ? '' : 'Diagnosa' }}</label>
          <div class="d-flex ma-0 pa-0 mb-4">
            <v-text-field
              :readonly="isEmr"
              dense
              placeholder="Masukkan diagnosa"
              v-model="form.diagnoses[index].diagnose"
              hide-details
            />
            <v-btn
              :disabled="isEmr || form.diagnoses.length < 2"
              icon
              @click="deleteDiagnose(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn :disabled="isEmr" icon @click="addDiagnose()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="6" :class="isEmr ? 'grid-display-emr' : 'grid-display'">
          <label>{{ index ? '' : 'ICD-10' }}</label>
          <v-autocomplete
            return-object
            :readonly="isEmr"
            hide-details
            class="icd-form"
            dense
            append-icon="mdi-chevron-down"
            item-text="displayName"
            v-model="form.diagnoses[index].icd10"
            @keyup="handleSearchICD10"
            :items="items.diagnose.items"
            :loading="items.diagnose.loading"
          />
        </v-col>
      </v-row>
    </div>

    <div v-for="(therapy, index) in form.therapies" :key="index">
      <v-row>
        <v-col cols="6" :class="isEmr ? 'grid-display-emr' : 'grid-display'">
          <label>
            {{ index ? '' : 'Rencana Terapi' }}
          </label>
          <div class="d-flex ma-0 pa-0 mb-4">
            <v-text-field
              :readonly="isEmr"
              dense
              placeholder="Masukkan rencana terapi"
              :id="'therapy-' + index"
              v-model="form.therapies[index].therapy"
            />
            <v-btn
              :disabled="isEmr || form.therapies.length < 2"
              icon
              @click="deleteTherapies(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn :disabled="isEmr" icon @click="addTherapies()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="6" :class="isEmr ? 'grid-display-emr' : 'grid-display'">
          <label> {{ index ? '' : 'ICD-9' }}</label>
          <v-autocomplete
            return-object
            :readonly="isEmr"
            class="icd-form"
            dense
            :key="'item' + index"
            append-icon="mdi-chevron-down"
            item-text="displayName"
            v-model="form.therapies[index].icd9"
            :id="'icd9-' + index"
            @keyup="handleSearchICD9"
            :items="items.procedure.items"
            :loading="items.procedure.loading"
          />
        </v-col>
      </v-row>
    </div>

    <div v-for="(intervention, index) in form.intervention" :key="index">
      <v-row>
        <v-col cols="6" :class="isEmr ? 'grid-display-emr' : 'grid-display'">
          <label> Intervensi {{ index + 1 }} </label>
          <div class="d-flex ma-0 pa-0 mb-4">
            <v-text-field
              :readonly="isEmr"
              dense
              placeholder="Masukkan intervensi"
              v-model="form.intervention[index].intervention"
              :id="'anamnesis-' + index"
            />
            <v-btn
              :disabled="isEmr || form.intervention.length < 2"
              icon
              @click="deleteIntervention(index)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-btn :disabled="isEmr" icon @click="addIntervention()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="6">
          <div
            :class="isEmr ? 'grid-display-emr' : 'grid-display'"
            v-for="(icd9, index2) in intervention.icd9"
            :key="index2"
          >
            <label>{{ index2 ? '' : 'ICD-9' }}</label>
            <div class="d-flex ma-0 pa-0 mb-4">
              <v-autocomplete
                return-object
                :readonly="isEmr"
                class="icd-form"
                dense
                append-icon="mdi-chevron-down"
                item-text="displayName"
                v-model="form.intervention[index].icd9[index2]"
                @keyup="handleSearchICD9"
                :items="items.procedure.items"
                :loading="items.procedure.loading"
              />
              <v-btn
                :disabled="isEmr || form.intervention[index].icd9.length < 2"
                icon
                @click="deleteICD9({ index, index2 })"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn :disabled="isEmr" icon @click="addICD9(index)">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-col cols="6">
        <v-row
          no-gutters
          align="baseline"
          :class="isEmr ? 'grid-display-emr' : 'grid-display'"
        >
          <label>Status Pulang</label>
          <v-col>
            <v-select
              :readonly="isEmr"
              item-value="code"
              item-text="name"
              dense
              validate-on-blur
              id="return_status"
              placeholder="Pilih Status Pulang"
              append-icon="mdi-chevron-down"
              :items="items.return_status.items"
              :loading="items.return_status.loading"
              v-model="form.return_status"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <div :class="isEmr ? 'grid-display-emr' : 'grid-display'">
          <label>Catatan Pemeriksaan Lain</label>
          <v-textarea
            :readonly="isEmr"
            dense
            outlined
            height="100"
            placeholder="Masukkan keterangan"
            v-model="form.other_inspection"
            :id="'action-' + index"
          />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'ServiceAssessmentPlanning',
);
const _ = require('lodash');
export default {
  name: 'ServiceAssessmentAssessmentPlanning',
  props: {
    isEmr: Boolean,
  },
  computed: {
    ...mapGetters(['getPlan', 'getItems']),
    form: {
      get() {
        return this.getPlan;
      },
    },
    items: {
      get() {
        return this.getItems;
      },
    },
  },
  methods: {
    ...mapActions([
      'resolveGetReturnStatus',
      'resolveGetProcedure',
      'resolveGetDiagnose',
    ]),
    ...mapMutations([
      'addDiagnose',
      'deleteDiagnose',
      'addTherapies',
      'deleteTherapies',
      'addIntervention',
      'deleteIntervention',
      'addICD9',
      'deleteICD9',
    ]),
    handleSearchICD9($event) {
      _.debounce(() => {
        this.resolveGetProcedure($event?.target?.value);
      }, 500)();
    },
    handleSearchICD10($event) {
      _.debounce(() => {
        this.resolveGetDiagnose($event?.target?.value);
      }, 500)();
    },
  },
  mounted() {
    this.resolveGetProcedure();
    this.resolveGetDiagnose();
    this.resolveGetReturnStatus();
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.4rem;
}
$standard-font-size: 0.9vw;

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}

.grid-display {
  @include grid-display(10vw minmax(16vw, 26vw), true);
  column-gap: 1vw;
}

.grid-display-emr {
  @include grid-display(10vw minmax(16vw, 34vw), true);
  column-gap: 1vw;
}

.required:after {
  content: ' *';
  color: red;
}

h4 {
  color: #1976d2;
}

::v-deep .v-list-item .v-list-item__content {
  line-height: 1.1;
  flex: 1 0 100%;
  text-align: start;
}
</style>
