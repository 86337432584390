import { createNamespacedHelpers } from 'vuex';
import DashboardQueueHeader from '../DashboardQueueHeader.vue';
const { mapGetters, mapActions } = createNamespacedHelpers('PatientQueue');
import PatientQueueMixin from '@/mixin/PatientQueueMixin';

const _ = require('lodash');

export default {
  name: 'DashboardQueueForPatient',
  mixins: [PatientQueueMixin],
  components: {
    DashboardQueueHeader,
  },
  data() {
    return {
      clinicName: 'neurovi clinic medical system',
      maxListContainerHeight: 0,
      isOverflow: false,
      loading: false,
      localStorageKey: 'queueOpenedTab',
      isActiveBrowserTab: false,
      openedTabs: 1,
    };
  },
  watch: {
    openedTabs(val) {
      this.setIsActiveBrowserTab(val);
    },
  },
  computed: {
    ...mapGetters(['getPublicQueue', 'getMissedPatientsForPublicView']),
    publicQueue: {
      get() {
        return this.assignMainColor(this.getPublicQueue);
      },
    },
    missedPatientsForPublicView: {
      get() {
        return this.getMissedPatientsForPublicView;
      },
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.incrementNumbersOfTabOpen();

      this.decrementNumbersOfTabOpen();

      this.openedTabs = +localStorage.getItem(this.localStorageKey);
      this.setIsActiveBrowserTab(this.openedTabs);
      this.maxListContainerHeight = window.innerHeight / 9;
      const debouncedSocketListen = _.debounce(() => {
        this.onUpdateBySocket();
        this.$socket.client.on('message:update-queue', () => {
          this.refresh();
        });
      }, 1000);

      debouncedSocketListen();
      this.refresh();
    });
  },
  methods: {
    ...mapActions(['resolveGetPublicQueue']),
    queueColorGenerator(unit) {
      switch (unit) {
        case 'Poli Fisioterapi':
          return '#7b1fa2';
        case 'Poli Umum':
          return '#1976d2';
        case 'Poli Gigi':
        case 'Poli Gigi dan Mulut':
        case 'Poli Gigi & Mulut':
          return '#f57f17';
        case 'Poli KIA':
          return '#2b9c4d';
        default:
          return '#f0f8fe';
      }
    },
    assignMainColor(queue) {
      return queue.map(item => {
        return {
          ...item,
          color: this.queueColorGenerator(item.id_unit.name),
        };
      });
    },
    checkOverflow() {
      const container = this.$el.querySelectorAll('.queue-list');
      let totalHeight = 0;
      container.forEach(node => {
        totalHeight += node.offsetHeight;
      });
      this.isOverflow = totalHeight > this.maxListContainerHeight;
    },
    // listen to message update socket
    async onUpdateBySocket() {
      if (!this.isActiveBrowserTab) return;
      this.onMessageUpdate({ refresh: this.refresh, isCalling: true });
      this.checkSound();
    },
    async refresh() {
      this.loading = true;
      await this.handleGetPublicQueue();
      this.checkOverflow();
      window.addEventListener('resize', this.checkOverflow);
      this.loading = false;
    },
    async handleGetPublicQueue() {
      await this.resolveGetPublicQueue();
    },
    incrementNumbersOfTabOpen() {
      const openedTabs =
        parseInt(localStorage.getItem(this.localStorageKey) || '0', 10) + 1;

      localStorage.setItem(this.localStorageKey, openedTabs.toString());
      window.addEventListener('storage', this.handleLocalStorageChange);
    },
    decrementNumbersOfTabOpen() {
      window.addEventListener('beforeunload', () => {
        const updatedOpenTabs = Math.max(
          (+localStorage.getItem(this.localStorageKey) || 0) - 1,
          0,
        );

        localStorage.setItem(this.localStorageKey, updatedOpenTabs.toString());

        window.addEventListener('storage', this.handleLocalStorageChange);
      });
    },
    handleLocalStorageChange(event) {
      if (event.key === this.localStorageKey) {
        this.openedTabs = +event.newValue || 0;
      }
    },
    setIsActiveBrowserTab(tabAmount) {
      this.isActiveBrowserTab = tabAmount === 1;
    },
  },
};
