<template>
  <v-card flat>
    <v-card-text class="pb-0">
      <v-container id="main-container" class="container-layout py-0" fluid>
        <v-form ref="form" lazy-validation class="containter-content">
          <service-assessment-subjective-data
            ref="subjective"
            id="subjective"
            :is-emr="isEmr"
          />

          <div>
            <h4 class="title font-weight-bold primary--text text-left mb-4">
              Data Objektif TTV
            </h4>
            <service-assessment-objective-data
              ref="objective"
              :is-editable="!isEmr"
              id="objective"
              isOther
              :is-emr="isEmr"
            />
          </div>

          <service-assessment-physical-check :is-emr="isEmr" />

          <service-assessment-supporting-investigation :is-emr="isEmr" />

          <service-assessment-assessment-planning :is-emr="isEmr" />
        </v-form>
      </v-container>
    </v-card-text>
    <hr class="mt-2" style="border-color: rgba(224, 224, 224, 0.3)" />
    <v-card-actions
      v-bind:class="{
        'd-flex': true,
        'justify-end': true,
        'justify-space-between': isPrintable,
      }"
    >
      <v-divider></v-divider>
      <v-btn
        v-if="!isEmr"
        @click="onClickActionButton('open-action')"
        width="138"
        height="36"
        color="success"
        class="text-none"
        depressed
      >
        Input Tindakan
      </v-btn>
      <v-btn
        v-if="!isEmr"
        width="108"
        height="36"
        class="text-none ml-15"
        text
        @click="handleClose"
      >
        Kembali
      </v-btn>
      <v-btn
        v-if="!isEmr"
        :loading="saveLoading"
        @click="handleSave()"
        width="108"
        height="36"
        color="primary"
        depressed
      >
        Simpan
      </v-btn>

      <v-btn
        v-if="isEmr"
        width="108"
        height="36"
        class="text-none ml-15"
        :text="isPrintable"
        color="primary"
        depressed
        dense
        @click="handleClose"
      >
        Kembali
      </v-btn>

      <v-btn
        @click="handlePrint()"
        width="108"
        height="36"
        color="primary"
        depressed
        class="text-none"
        v-if="isPrintable"
      >
        Print
      </v-btn>
    </v-card-actions>
    <v-dialog v-model="dialog" persistent max-width="1200px">
      <BHPandAction
        v-if="selectedForm === 1 && dialog"
        :patient-data="patientData"
        @close-form="dialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2';
import Constant from '@/const';
import { createNamespacedHelpers } from 'vuex';
import ServiceAssessmentObjectiveData from '../AssessmentComponents/ServiceAssessmentObjectiveData.vue';
import ServiceAssessmentPhysicalCheck from '../AssessmentComponents/ServiceAssessmentPhysicalCheck.vue';
import ServiceAssessmentSubjectiveData from '../AssessmentComponents/ServiceAssessmentSubjectiveData.vue';
import ServiceAssessmentAssessmentPlanning from '../AssessmentComponents/ServiceAssessmentAssessmentPlanning.vue';
import ServiceAssessmentSupportingInvestigation from '../AssessmentComponents/ServiceAssessmentSupportingInvestigation';
import AssessmentMapperMixin from '@/mixin/MapperMixins/Assessment';
import { ModalConfirmation } from '@/components/SharedComponent';
import assestmentMixin from '@/mixin/assestmentMixin';
import printMixin from '@/mixin/printMixin';
import { scrollToEmptyInput } from '@/helper';
import BHPandAction from '../BHPandAction.vue';

const {
  mapGetters: objectiveGetters,
  mapMutations: objectiveMutations,
} = createNamespacedHelpers('ServiceAssessmentObjectiveData');
const {
  mapGetters: planningGetters,
  mapMutations: planningMutations,
} = createNamespacedHelpers('ServiceAssessmentPlanning');
const {
  mapGetters: physicalGetters,
  mapMutations: physicalMutations,
} = createNamespacedHelpers('ServiceAssessmentPhysicalCheck');
const {
  mapGetters: subjectiveGetters,
  mapMutations: subjectiveMutations,
} = createNamespacedHelpers('ServiceAssessmentSubjectiveData');
const {
  mapGetters: supportGetters,
  mapMutations: supportMutations,
} = createNamespacedHelpers('ServiceAssessmentSupportingInvestigation');

const _ = require('lodash');
const axios = require('axios');
export default {
  name: 'inputAssesment',
  props: {
    patientData: {
      type: Object,
      default: null,
    },
    emrData: {
      type: Object,
      default: null,
    },
    isEmr: Boolean,
  },
  mixins: [AssessmentMapperMixin, assestmentMixin, printMixin],
  components: {
    BHPandAction,
    ServiceAssessmentSubjectiveData,
    ServiceAssessmentObjectiveData,
    ServiceAssessmentPhysicalCheck,
    ServiceAssessmentSupportingInvestigation,
    ServiceAssessmentAssessmentPlanning,
  },
  computed: {
    ...objectiveGetters(['getObjective']),
    ...planningGetters(['getPlan', 'getItems']),
    ...physicalGetters(['getPhysicalCheck']),
    ...subjectiveGetters(['getSubjectData']),
    ...supportGetters(['getSupportingInvestigation']),
    form: {
      get() {
        return {
          plan: { ...this.getPlan },
          objective: { ...this.getObjective },
          physical: { ...this.getPhysicalCheck },
          subjective: { ...this.getSubjectData },
          support: { ...this.getSupportingInvestigation },
        };
      },
    },
    isPrintable: {
      get() {
        return this.isEmr && this.isSaved;
      },
    },
  },
  data() {
    return {
      saveLoading: false,
      defaultValue: {},
      isEqual: true,
      isSaved: false,
      selectedForm: null,
      dialog: false,
    };
  },
  watch: {
    form: function(val) {
      this.isEqual = _.isEqual(val, this.defaultValue);
    },
  },

  methods: {
    ...objectiveMutations(['clearObjectiveState', 'setObjective']),
    ...planningMutations(['clearPlanning', 'setPlanning']),
    ...physicalMutations(['clearPhysical', 'setPhysical']),
    ...subjectiveMutations(['clearSubjective', 'setSubjective']),
    ...supportMutations(['clearSupportingInvestigationState', 'setSupport']),
    changeForm(val) {
      this.selectedForm = val;
      this.dialog = true;
    },
    async onClickActionButton(type) {
      const isValidated =
        this.$refs.objective.$refs.objectiveForm.validate() &&
        this.$refs.subjective.$refs.subjectiveForm.validate();

      switch (type) {
        case 'open-action':
          if (await !isValidated) {
            Swal.fire({
              title: 'Data Assesmen Poli Umum Ada yang Belum Diisi',
              text:
                'Silakan lengkapi terlebih dahulu sebelum menginput Tindakan',
              icon: 'warning',
            });
            scrollToEmptyInput();
            return;
          }
          this.handleSave(true);
          break;
      }
    },
    async handleSave(openAction = false) {
      const isValidated =
        this.$refs.objective.$refs.objectiveForm.validate() &&
        this.$refs.subjective.$refs.subjectiveForm.validate();
      if (await isValidated) {
        this.saveLoading = true;
        const payload = this.mapPayloadAssessmentGeneral(this.form);
        delete payload.objective.awareness;
        delete payload.physical;
        delete payload.plan;
        axios
          .post(
            Constant.apiUrl.concat(
              `/patient/emr/checkup/general/${this.patientData.id_registration}`,
            ),
            payload,
          )
          .then(response => {
            if (response.status == 200) {
              this.saveLoading = false;
              Swal.fire('Data Assesmen Berhasil Disimpan!', '', 'success').then(
                result => {
                  if (
                    (result.isConfirmed || result.isDismissed) &&
                    openAction
                  ) {
                    this.changeForm(1);
                    this.formHasChanged = false;
                  }
                  if (
                    (result.isConfirmed || result.isDismissed) &&
                    !openAction
                  ) {
                    this.$emit('close-form');
                  }
                },
              );
              this.$emit('refresh-history');
              this.handleGet();
            } else {
              Swal.fire('Gagal', response.data.pesan, 'error');
            }
          })
          .finally(() => {
            this.saveLoading = false;
          });
      } else {
        scrollToEmptyInput();
      }
    },
    async handleGet() {
      await axios
        .get(
          Constant.apiUrl.concat(
            `/patient/checkup/regist/${this.patientData.id_registration}/service`,
          ),
        )
        .then(response => {
          const { data } = response.data;
          // to check wether form is had been saved or not
          this.isSaved = Boolean(
            data.timestamps?.doctor_by || data.timestamps.nurse_by,
          );
          const awarenssItem = [
            { name: 'Compos Mentis', code: '01' },
            { name: 'Somnolence', code: '02' },
            { name: 'Sopor', code: '03' },
            { name: 'Coma', code: '04' },
          ];
          const includesAny = ['Cemas', 'Takut', 'Marah', 'Sedih'];
          const hasAnyEmotion = includesAny.some(emotion =>
            data.subjective.psychological_status.includes(emotion),
          );
          this.setSubjective({
            ...data.subjective,
            allergy_history:
              data.subjective.allergy_history.length === 0
                ? ['']
                : data.subjective.allergy_history,
            anamnesis:
              data.subjective.anamnesis.length === 0
                ? ['']
                : data.subjective.anamnesis,
            disease_history:
              data.subjective.disease_history.length === 0
                ? ['']
                : data.subjective.disease_history,
            psychologyStatusCheckbox:
              hasAnyEmotion ||
              !data.subjective.psychological_status.length === 0,
          });
          const zeroToEmpty = value => (value === 0 ? '' : value);
          this.setObjective({
            sistol: zeroToEmpty(data.objective.sistol),
            diastol: zeroToEmpty(data.objective.diastol),
            weight: zeroToEmpty(data.objective.weight),
            height: zeroToEmpty(data.objective.height),
            belly_circumference: zeroToEmpty(
              data.objective.belly_circumference,
            ),
            respiration_rate: zeroToEmpty(data.objective.respiration_rate),
            saturation: zeroToEmpty(data.objective.saturation),
            temperature: zeroToEmpty(data.objective.temperature),
            pulse: zeroToEmpty(data.objective.heart_rate),
            awareness: awarenssItem.find(
              item => item.code === data.objective.awarness,
            ),
            other: zeroToEmpty(data?.objective?.other),
          });
          this.setPhysical(data.assesment.physical);
          this.setSupport(data.other);
          this.setPlanning({
            other_inspection: data.assesment.other_inspection,
            return_status: this.getItems.return_status.items.find(
              item => item.code === data.assesment.return_status,
            ),
            diagnoses:
              data.assesment.diagnoses.length === 0
                ? [{ diagnose: '', icd10: '' }]
                : data.assesment.diagnoses.map(item => ({
                    diagnose: item.text,
                    icd10:
                      item.id_diagnose === undefined
                        ? ''
                        : {
                            id: item?.id_diagnose,
                            code: item?.code,
                            text: item?.description,
                            displayName: `${item?.code} ${item?.description}`,
                          },
                  })),
            therapies:
              data.planning.general_theraphy.length === 0
                ? [{ therapy: '', icd9: '' }]
                : data.planning.general_theraphy.map(item => ({
                    therapy: item.text,
                    icd9:
                      item.icd9 === undefined
                        ? ''
                        : {
                            id: item?.icd9?.id,
                            code: item?.icd9?.code,
                            text: item?.icd9?.description,
                            displayName: `${item?.icd9?.code} ${item?.icd9?.description}`,
                          },
                  })),
            intervention:
              data.planning.interventions.length === 0
                ? [{ intervention: '', icd9: [''] }]
                : data.planning.interventions.map(item => ({
                    intervention: item.text,
                    icd9:
                      item.icd9.length === 0
                        ? ['']
                        : item.icd9.map(procedure => ({
                            id: procedure?.id,
                            code: procedure?.code,
                            text: procedure?.description,
                            displayName:
                              procedure?.code === undefined
                                ? null
                                : `${procedure?.code} ${procedure?.description}`,
                          })),
                  })),
          });
          this.getItems.diagnose.items = this.form.plan.diagnoses.map(v => ({
            id: v.icd10.id,
            code: v.icd10.code,
            text: v.icd10.text,
            displayName: v.icd10.displayName,
          }));
          this.defaultValue = Object.assign({}, this.form);
        });
    },
    handlePrint() {
      const doc = this.generateOutpatientSummary(
        this.patientData,
        {
          ...this.emrData,
          return_status_name: this.form?.plan?.return_status?.name || '-',
        },
        null,
        true,
      );
      this.printDocument(doc);
    },
    handleClose() {
      if (!this.isEqual) {
        ModalConfirmation({
          title: `<p>Data Belum Tersimpan</p>`,
          icon: 'exclamation',
          desc: `<p>Data yang anda input akan hilang. Kembali dan</p> <p>simpan formulir terlebih dahulu</p>`,
          confirmButton: 'Keluar',
          cancelButton: 'Kembali',
          confirmStyle: 'button-error',
          cancelStyle: 'button-confirm',
          reverseButtons: false,
        }).then(result => {
          if (result.isConfirmed) {
            this.$emit('close-form');
            this.handleClearForm();
          }
        });
      } else {
        this.$emit('close-form');
        this.handleClearForm();
      }
    },
    handleClearForm() {
      this.clearObjectiveState();
      this.clearPlanning();
      this.clearPhysical();
      this.clearSubjective();
      this.clearSupportingInvestigationState();
    },
  },
  mounted() {
    this.handleGet();
  },
  beforeDestroy() {
    this.handleClearForm();
  },
};
</script>

<style lang="scss" scoped>
$standard-font-size: 0.9vw;

@mixin result-chip($fill, $outline) {
  background-color: $fill;
  border: solid $outline 1.5px;
  border-radius: 6px;
  color: $outline;
  font-weight: 450;
  padding: 0.3rem 0.5rem;
  width: max-content;
  height: max-content;
  font-size: calc($standard-font-size - 0.1vw);
}

@mixin grid-display($column, $isLabel: false) {
  display: grid;
  grid-template-columns: $column;

  @if $isLabel {
    font-size: $standard-font-size;
    label {
      font-weight: 500;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
    }
  }
}
.icd-input {
  margin-bottom: 1.5rem;
}
.referral-select {
  @include grid-display(0.6fr 1fr);
}

.container-layout {
  height: 76vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
  .chip-green {
    @include result-chip(#e5fff0, #2d965a);
  }
  .chip-yellow {
    @include result-chip(#fff9e5, #e5a82e);
  }
  .chip-red {
    @include result-chip(#fff2f2, #eb4747);
  }
  .chip-grey {
    @include result-chip(#f6f6f6, #928f8f);
  }
  .title {
    font-size: 1.4rem;
  }

  .dental-stage {
    display: flex;
    justify-content: center;
  }
  .dental-input {
    display: grid;
    grid-template-columns: 190px 250px;
  }
  .fixed-button {
    z-index: 10;
    width: 15vw;
    background-color: #deeef9 !important;
    position: fixed;
    bottom: 15vh;
    right: 5vw;
    color: #429ddb;
  }
  .grid-display {
    @include grid-display(10vw minmax(16vw, 26vw), true);
    column-gap: 1vw;
  }

  .grid-nutrition {
    @include grid-display(80% 20%, true);

    &.adult {
      @include grid-display(60% 40%, true);
    }
    p {
      font-weight: 300;
      text-align: start;
      margin-left: 1vw;
      font-size: 0.9vw;
      color: #757575;
      margin-top: 0.4em;
    }
  }

  .bounce {
    animation: scale 0.2s infinite;
  }
  @keyframes scale {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(0.8);
    }
    100% {
      transform: scale(1);
    }
  }
}

.title-container {
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(246, 246, 246);
}
.required:after {
  content: ' *';
  color: red;
}
.containter-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2rem;
}
</style>
