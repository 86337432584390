import JsPDF from 'jspdf';
import moment from 'moment-timezone';
import printMixin from './printMixin';
import QRCode from 'qrcode';

export default {
  methods: {
    printItemOrder(clientData, supplier, letterData, table, userLoggedIn) {
      const doc = new JsPDF({
        orientation: 'potrait',
        unit: 'cm',
        format: 'a4',
      }).setProperties({
        title: `SP ${supplier.orderNumber}`,
      });

      const marginX = 1.5;
      const marginY = 4;
      const perLine = doc.getTextDimensions(' ').h;
      const addLine = line => perLine * line + 1.3;
      const pageWidth =
        doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
      const pageHeight =
        doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

      doc.setFontSize(10);
      doc.setFont('Helvetica', 'normal');
      const placeAndDate = `${userLoggedIn.clinic.clinicRegion}, ${moment(
        supplier.timestamps,
      )
        .locale('id')
        .format('DD MMMM YYYY')}`;
      doc.text(placeAndDate, 15, pageHeight - 24.5, 'center');

      doc.autoTable({
        body: [
          ['No. SP', `: ${supplier.orderNumber}`],
          ['Tgl. Order', `: ${supplier.date}`],
          ['NPWP No', `: `],
        ],

        margin: {
          left: marginX,
          right: marginX,
          top: marginY + 0.5,
        },
        columnStyles: {
          0: { cellWidth: 3, fontSize: 11 },
          1: { cellWidth: 6, fontSize: 11 },
        },
        theme: 'plain',
      });

      doc.setFontSize(12);
      doc.text(
        `Kepada Yth.  ${supplier.supplierName.name}`, // supplier name
        marginX,
        pageHeight - 20.9,
      );
      doc.setFontSize(12);

      if (supplier.supplierName.address) {
        const addressLines = doc.splitTextToSize(
          supplier.supplierName.address,
          15,
        );
        doc.text(addressLines, marginX, pageHeight - 20); // supplier address
      }

      doc.setFontSize(12);
      doc.text('Dengan hormat,', marginX, pageHeight - 18.8);

      doc.setFontSize(12);
      doc.text(
        'Kami bermaksud memesan barang sebagai berikut :',
        marginX,
        pageHeight - 18,
      );

      doc.setFontSize(10);
      const infoTable = doc.autoTable.previous;
      const items = table.detail.map((arr, index) => {
        return [index + 1, arr.name, `${arr.quantity} ${arr.package || ''}`];
      });

      let lastDidDrawPageData = null;
      doc.autoTable({
        head: [['No.', 'Nama Barang', 'Jumlah']],
        headStyles: {
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          lineWidth: 0.03,
        },
        bodyStyles: { lineColor: [0, 0, 0, 1], lineWidth: 0.03 },
        body: items,
        startY: infoTable.finalY + 6,
        margin: {
          left: marginX,
          right: marginX,
          top: addLine(5.6),
        },
        columnStyles: {
          0: { cellWidth: 2, fontSize: 11 },
          1: { cellWidth: 12, fontSize: 11 },
          2: { cellWidth: 3, fontSize: 11 },
        },
        theme: 'plain',
        didDrawPage: d => {
          lastDidDrawPageData = d;
        },
      });

      if (lastDidDrawPageData.cursor.y <= 25.9) {
        doc.setFontSize(12);
        doc.text(
          `Demikian surat pemesanan barang ini kami sampaikan kepada saudara, atas kerjasama`,
          marginX,
          doc.lastAutoTable.finalY + 1,
        );
        doc.setFontSize(12);
        doc.text(
          `dan perhatian saudara kami ucapkan terima kasih.`,
          marginX,
          doc.lastAutoTable.finalY + 1.7,
        );
      }
      let finalY = doc.lastAutoTable.finalY;

      if (lastDidDrawPageData.cursor.y >= 21.9) {
        doc.addPage();
        finalY = 1.5;
      }

      if (lastDidDrawPageData.cursor.y >= 25.9) {
        finalY += 2.5;
        doc.setFontSize(12);
        doc.text(
          `Demikian surat pemesanan barang ini kami sampaikan kepada saudara, atas kerjasama`,
          marginX,
          finalY + 1,
        );
        doc.setFontSize(12);
        doc.text(
          `dan perhatian saudara kami ucapkan terima kasih.`,
          marginX,
          finalY + 1.7,
        );
      }

      finalY += 2.5;

      this.generateQRCode(
        {
          letterName: 'Surat Pemesanan Barang',
          doctor: userLoggedIn.name,
          sip: null,
          doc: doc,
          x: marginX - 0.3,
          y: finalY,
        },
        QRCode,
        moment,
      );
      doc.setFontSize(12);
      doc.text(`Hormat Kami,`, pageWidth / 1.5, finalY + 0.5, 'center');
      doc.text(`${userLoggedIn.name}`, pageWidth / 1.5, finalY + 4, 'center');

      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        // Letter Head
        printMixin.methods.letterHead(
          doc,
          this.$store.getters.userLoggedIn.clinic,
          'cm',
        );
      }

      return doc;
    },
  },
};
