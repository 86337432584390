<template>
  <div>
    <v-layout pa-4 align-center>
      <v-flex xs12 md6 class="text-left">
        <h2>Penyerahan Obat</h2>
      </v-flex>
      <v-flex xs3>
        <Datepicker
          :show="datePicker"
          :date="date"
          :isClearable="true"
          @date-change="handleDateChange"
        />
      </v-flex>
      <v-flex xs1>
        <router-link :to="routeBack" active>
          <font-awesome-icon class="mr-3" style="color: #3f484a" icon="home" />
        </router-link>
        <v-btn @click="refresh()" icon>
          <font-awesome-icon :class="{ refresh: isRefresh }" icon="sync-alt" />
        </v-btn>
      </v-flex>
      <v-flex xs4 md4>
        <v-text-field
          solo
          dense
          flat
          label="Cari Pasien"
          prepend-inner-icon="mdi-magnify"
          class="search"
          background-color="grey lighten-3"
          v-model="search"
          hide-details="false"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <div class="header">
      <v-tabs v-model="tab" grow slider-color="blue">
        <v-tab key="service">
          <label class="text-none font-weight-bold" @change="getAllPatient()"
            >Pelayanan ({{ count.service }})</label
          >
        </v-tab>
        <v-tab key="takeHomeDrug">
          <label class="text-none font-weight-bold" @change="getAllPatient()"
            >Obat dibawa pulang ({{ count.takeHome }})</label
          >
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item key="service">
        <v-data-table
          :headers="headers"
          :items="data.service"
          item-key="id"
          :items-per-page="itemsPerRow"
          single-expand
          :expanded="expanded"
          :height="height"
          hide-default-footer
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          :page.sync="servicePage"
          :loading="isLoading"
        >
          <template v-slot:[`item.no`]="{ item }">
            <tr class="text-left">
              <td>
                {{ item.no }}
              </td>
            </tr>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              label
              class="chip"
              :class="
                item.status === 'Sudah diserahkan' ? 'delivered' : 'undelivered'
              "
            >
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length" class="expand px-0">
              <v-card flat>
                <v-row no-gutters class="pb-2">
                  <v-col cols="1"></v-col>
                  <v-col
                    cols="3"
                    class="text-left pl-5 grey--text caption"
                    style="max-width: 300px"
                  >
                    <span
                      >{{ item.gender }} <br />
                      {{ item.age }} Tahun</span
                    ></v-col
                  >
                </v-row>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right">
                <v-container class="py-0 px-0">
                  <v-row no-gutters>
                    <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="onClick(item, 1)"
                          >
                            <v-icon>mdi-account</v-icon>
                          </v-btn>
                        </template>
                        <span>Data Sosial</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="onClick(item, 2)"
                          >
                            <v-icon>mdi-file-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Dokumen</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </td>
          </template>
          <template v-slot:no-data> Data tidak ditemukan </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item key="takeHomeDrug">
        <v-data-table
          :headers="headers"
          :items="data.takeHome"
          item-key="id"
          :items-per-page="itemsPerRow"
          single-expand
          :expanded="expanded"
          :height="height"
          hide-default-footer
          @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
          :page.sync="takeHomePage"
          :loading="isLoading"
        >
          <template v-slot:[`item.no`]="{ item }">
            <tr class="text-left">
              <td>
                {{ item.no }}
              </td>
            </tr>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              label
              class="chip"
              :class="
                item.status === 'Sudah diserahkan' ? 'delivered' : 'undelivered'
              "
            >
              {{ item.status }}
            </v-chip>
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <td :colspan="headers.length" class="expand px-0">
              <v-card flat>
                <v-row no-gutters class="pb-2">
                  <v-col cols="1"></v-col>
                  <v-col
                    cols="3"
                    class="text-left pl-5 grey--text caption"
                    style="max-width: 300px"
                  >
                    <span
                      >{{ item.gender }} <br />
                      {{ item.age }} Tahun</span
                    ></v-col
                  >
                </v-row>
              </v-card>

              <v-card flat color="grey lighten-4" class="text-right">
                <v-container class="py-0 px-0">
                  <v-row no-gutters>
                    <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-bind="attrs"
                            v-on="on"
                            @click="onClick(item, 1)"
                          >
                            <v-icon>mdi-account</v-icon>
                          </v-btn>
                        </template>
                        <span>Data Sosial</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="onClick(item, 2)"
                          >
                            <v-icon>mdi-file-edit</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Dokumen</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template #activator="{ on: tooltip }">
                          <v-btn
                            icon
                            class="mx-2 hover"
                            v-on="{ ...tooltip }"
                            @click="onClick(item, 3)"
                          >
                            <v-icon>mdi-printer</v-icon>
                          </v-btn>
                        </template>
                        <span>Cetak E-Resep</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>

                  <v-dialog>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        class="icon"
                        slot="activator"
                        v-bind="attrs"
                        v-on="on"
                      ></div>
                    </template>
                  </v-dialog>
                </v-container>
              </v-card>
            </td>
          </template>
          <template v-slot:no-data> Data tidak ditemukan </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>

    <v-footer color="white">
      <v-container>
        <v-row>
          <v-pagination
            v-model="servicePage"
            :length="serviceLengthPage"
            :total-visible="10"
            v-if="tab === 0"
            @input="getAllPatient()"
          ></v-pagination>
          <v-pagination
            v-model="takeHomePage"
            :length="takeHomeLengthPage"
            :total-visible="10"
            v-if="tab === 1"
            @input="getAllPatient()"
          ></v-pagination>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
      <v-snackbar
        class="mb-13"
        color="success"
        timeout="4000"
        v-model="snackbar"
      >
        <div class="d-flex justify-space-between">
          <label class="mr-3">Penyerahan obat berhasil disimpan</label>
          <v-icon @click.native="snackbar = false" small color="#ffff"
            >mdi-close</v-icon
          >
        </div>
      </v-snackbar>
    </v-footer>
    <v-dialog
      v-model="dialog"
      :max-width="1200"
      persistent
      :fullscreen="selectedDialog === 2"
    >
      <SocialData
        v-if="selectedDialog === 1 && dialog"
        :medical-record="selectedPatient.rm_number"
        @close-form="dialog = false"
      ></SocialData>
      <DrugHandoverEdit
        v-else-if="selectedDialog === 2 && dialog"
        :patient="selectedPatient"
        @close-form="
          dialog = false;
          getAllPatient();
        "
        @show-snackbar="showSnackbar()"
      />
    </v-dialog>
  </div>
</template>

<script>
import DrugHandoverEdit from './DrugHandover/DrugHandoverEdit';
import jwtMixin from '@/mixin/jwtMixin';
import SocialData from '../../SharedComponent/SocialData.vue';
import alertMixin from '@/mixin/alertMixin';
import printDrugHandoverMixin from '@/mixin/printDrugHandoverMixin';
import printMixin from '@/mixin/printMixin';
import {
  getPrintPharmacy,
  getPharmacyPatientCount,
  getPharmacyPatientByCategory,
} from '@/fetchApi/Pharmacy';
import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';

const _ = require('lodash');
const moment = require('moment');

export default {
  name: 'DrugHandover',
  mixins: [jwtMixin, alertMixin, printMixin, printDrugHandoverMixin],
  components: {
    DrugHandoverEdit,
    SocialData,
    Datepicker,
  },
  data() {
    return {
      tab: 1,
      snackbar: false,
      expanded: [],
      isExpanded: false,
      isRefresh: false,
      date: moment().format('YYYY-MM-DD'),
      datePicker: false,
      selectedDate: '',
      headers: [
        { text: 'No', align: 'start', value: 'no' },
        { text: 'Nama', value: 'name', width: '25%' },
        { text: 'No. RM.', value: 'rm_number' },
        { text: 'Tipe Penjaminan', value: 'assurance' },
        { text: 'Unit', value: 'unit' },
        { text: 'Dokter', value: 'doctor' },
        { text: 'Status', value: 'status' },
      ],
      data: {
        service: [],
        takeHome: [],
      },
      count: {
        service: 0,
        takeHome: 0,
      },
      dialog: false,
      isLoading: false,
      servicePage: 1,
      takeHomePage: 1,
      rowsPerPage: 5,
      serviceLengthPage: 0,
      takeHomeLengthPage: 0,
      selectedDialog: null,
      selectedPatient: null,
      search: '',
    };
  },
  watch: {
    tab() {
      this.getAllPatient();
    },
    search() {
      this.searchData(this);
    },
    selectedDate() {
      this.date = moment(this.selectedDate).format('DD-MM-YYYY');
      this.getAllPatient();
    },
    date() {
      if (!this.date) {
        this.date = '';
        this.getAllPatient();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.refreshAndAutoUpdate();
    });
  },
  computed: {
    route() {
      return this.$route.path;
    },
    routeSplit() {
      return this.$route.path.split('/');
    },
    routeController() {
      return this.routeSplit[2];
    },
    routeBack() {
      return `/farmasi/${this.routeController}`;
    },
    routeName() {
      switch (this.routeController) {
        case 'rawat-inap':
          return 'rawat inap';

        case 'rawat-jalan':
          return 'rawat jalan';

        case 'igd':
          return 'IGD';

        default:
          return '';
      }
    },
    itemsPerRow() {
      let itemRow = '';
      switch (this.$vuetify.breakpoint.name) {
        case 'xs' || 'sm' || 'md':
          itemRow = 5;
          break;
        case 'lg':
          itemRow = 7;
          break;
        case 'xl':
          itemRow = 12;
          break;
        default:
          return 5;
      }
      return itemRow;
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '58vh';
          break;
        case 'lg':
          height = '60vh';
          break;
        case 'xl':
          height = '67vh';
          break;
      }
      return height;
    },
  },
  methods: {
    searchData: _.debounce(v => {
      // eslint-disable-next-line no-param-reassign
      v.page = 1;
      v.getAllPatient();
    }, 650),
    handleDateChange(updatedDate) {
      this.date = updatedDate;
      this.servicePage = 1;
      this.getAllPatient();
    },
    refresh() {
      this.date = '';
      this.search = '';
      this.isRefresh = true;
      this.getAllPatient();
    },
    showSnackbar() {
      this.snackbar = true;
    },
    async getPatientCount() {
      try {
        const params = {
          category: 'handover',
          date: this.date || '',
          type: this.routeName.toLowerCase(),
          search: this.search,
        };
        const response = await getPharmacyPatientCount(params);
        this.count = {
          service: response.pharmacyNotTakeHomeCount,
          takeHome: response.pharmacyTakeHomeCount,
        };
      } catch (error) {
        this.count = {
          service: 0,
          takeHome: 0,
        };
      }
    },
    async getAllPatient() {
      try {
        this.getPatientCount(); // get amount of patient first
        this.isLoading = true;
        const params = {
          date: this.date || '',
          category: 'handover',
          type: this.routeName.toLowerCase(),
          page: this.tab ? this.takeHomePage : this.servicePage,
          itemCount: this.itemsPerRow,
          search: this.search || '',
          takeHome: this.tab,
        };
        const response = await getPharmacyPatientByCategory(params);
        const patient = response.data.data;
        const dataLength = response.data.length;
        if (this.tab) {
          this.takeHomeLengthPage = Math.ceil(dataLength / this.itemsPerRow);
          this.data.takeHome = patient.map((data, index) => {
            return {
              no: (this.takeHomePage - 1) * this.itemsPerRow + index + 1,
              id: data._id.toString(),
              id_emr: data.id_recipe.id_emr,
              id_registration: data.id_recipe.id_registration,
              name: data.name,
              rm_number: data.medical_record_number,
              assurance: data.assurance.toUpperCase(),
              unit: data.unit,
              gender: data.gender ? 'Laki-laki' : 'Perempuan',
              age: data.age,
              doctor: data.doctor,
              status:
                data.status === 'finish'
                  ? 'Sudah diserahkan'
                  : 'Belum diserahkan',
              officer: data.staff_order,
            };
          });
        } else {
          this.serviceLengthPage = Math.ceil(dataLength / this.itemsPerRow);
          this.data.service = patient.map((data, index) => {
            return {
              no: (this.servicePage - 1) * this.itemsPerRow + index + 1,
              id: data._id.toString(),
              id_emr: data.id_recipe.id_emr,
              id_registration: data.id_recipe.id_registration,
              name: data.name,
              rm_number: data.medical_record_number,
              assurance: data.assurance.toUpperCase(),
              unit: data.unit,
              gender: data.gender ? 'Laki-laki' : 'Perempuan',
              age: data.age,
              doctor: data.doctor,
              status:
                data.status === 'finish'
                  ? 'Sudah diserahkan'
                  : 'Belum diserahkan',
              officer: data.staff_order,
            };
          });
        }
      } catch {
        this.data.takeHome = [];
        this.takeHomeLengthPage = 1;
      } finally {
        this.isRefresh = false;
        this.isLoading = false;
      }
    },

    refreshAndAutoUpdate() {
      if (this.interval !== null) {
        this.interval = clearInterval(this.interval);
      }
      this.getAllPatient();
      this.interval = setInterval(this.getAllPatient, 60000);
    },

    async onClick(item, val) {
      if (val === 3) {
        let response = await getPrintPharmacy(item.id);
        response.rm_number = item.rm_number;
        const doc = this.generateDrugHandover(response);
        this.printDocument(doc, {
          docName: 'E-RESEP',
          rmNumber: item.rm_number,
        });
      } else {
        this.selectedDialog = val;
        this.selectedPatient = item;
        this.dialog = true;
      }
    },
    beforeDestroy() {
      this.interval = clearInterval(this.interval);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  text-align: left;
  padding: 10px 25px 25px 25px;
}

.btn-refresh,
.search {
  margin-top: 20px;
}
.header {
  border-bottom: 1px solid #e7e7e7;
}

.icon {
  float: right;
  padding-left: 20px;
}
.refresh {
  animation: rotation 0.23s infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.expand {
  border: none;
  background-color: white;
}
::v-deep {
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > td:not(.v-data-table__mobile-row),
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > tbody
    > tr:not(:last-child)
    > th:not(.v-data-table__mobile-row) {
    border: none;
  }
}

.chip {
  &.delivered {
    border: 1px solid #2d965a;
    color: #2d965a;
    background-color: #e5fff0;
  }
  &.undelivered {
    border: 1px solid #2f80ed;
    color: #2f80ed;
    background-color: #f0f9ff;
  }
}
</style>
