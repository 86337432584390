<template>
  <v-card style="min-height: 90vh; min-width: 100%">
    <v-card-title class="justify-center">
      <span class="font-weight-bold">Detail Stok Opname</span>
      <v-btn
        absolute
        right
        fab
        @click="dialog = false"
        small
        text
        icon
        color="primary"
      >
        <v-icon dark @click="onCloseButtonClick()"> mdi-close-circle </v-icon>
      </v-btn>
    </v-card-title>

    <v-container fluid class="px-14 mb-5">
      <v-row class="mb-3">
        <v-col
          class="py-0 pl-6 font-weight-medium grey--text mb-2 text-start"
          cols="1"
        >
          <span>Unit</span>
        </v-col>
        <v-col class="py-0 text-start" cols="2">
          <span>{{ this.dataTransaction.unitTo }}</span>
        </v-col>
        <v-col class="py-0 align-center d-flex justify-end">
          <v-text-field
            class="input"
            background-color="grey lighten-3"
            placeholder="Search"
            v-model="table.search"
            solo
            dense
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        class="table-content"
        :headers="headers"
        :items="items"
        :search="table.search"
        fixed-header
        :items-per-page="5"
      >
        <template v-slot:item="{ item, index }">
          <tr class="row-datatable" :key="index">
            <td class="text-left">
              {{ item.no }}
            </td>
            <td class="text-left">{{ item.name }}</td>
            <td class="text-left">{{ item.supplier }}</td>
            <td class="text-left">{{ item.expDate }}</td>
            <td class="text-left">{{ item.batch }}</td>
            <td class="text-left">{{ item.residual }}</td>
            <td class="text-left">{{ item.realStock }}</td>
            <td class="text-left">
              {{ item.reason }}
            </td>
          </tr>
        </template>
      </v-data-table>
      <!-- <v-row class="table-content">
        <v-col
          cols="12"
          v-for="(item, index) in items"
          :key="'corrected-item-' + index"
          class="grid-display table-items"
        >
          <span class="text-center">
            {{
              (table.pagination.page - 1) * table.pagination.rowsPerPage +
                index +
                1
            }}
          </span>
          <span class="text-left">{{ item.name }}</span>
          <span class="text-center">{{ item.expDate }}</span>
          <span class="text-center">{{ item.batch }}</span>
          <span class="text-center">{{ item.residual }}</span>
          <span class="text-center">{{ item.realStock }}</span>
          <span class="text-center">
            {{ item.reason }}
          </span>
        </v-col>
      </v-row> -->
    </v-container>
    <v-container fluid class="px-14">
      <v-row>
        <v-col cols="3">
          <v-col
            v-show="dataTransaction.status === 'requested' && isManager"
            cols="12"
            class="text-sm-left"
          >
            <span class="font-weight-medium"> Approval </span>
          </v-col>
          <v-col cols="12" class="d-flex justify-start py-0">
            <v-btn
              class="mr-2 text-capitalize"
              color="primary"
              width="120"
              depressed
              @click="approval('resolved')"
              height="30"
              v-show="dataTransaction.status === 'requested' && isManager"
            >
              Ya
            </v-btn>
            <v-btn
              height="30"
              class="text-capitalize"
              color="grey"
              outlined
              @click="approval('rejected')"
              width="120"
              v-show="dataTransaction.status === 'requested' && isManager"
            >
              Tidak
            </v-btn>
          </v-col>
        </v-col>
        <v-col cols="6">
          <v-row class="mb-5">
            <v-col cols="12">
              <p
                v-show="dataTransaction.status === 'requested' && isManager"
                class="font-weight-medium text-left"
              >
                Catatan Approval
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                v-show="dataTransaction.status === 'requested' && isManager"
                no-resize
                height="40"
                v-model="dataTransaction.note"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3"> </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import Constant from '@/const';
import AlertMixin from '@/mixin/alertMixin';

const axios = require('axios');
const moment = require('moment-timezone');
export default {
  mixins: [AlertMixin],
  data() {
    return {
      isLoading: false,
      unit: '',
      checked: [],
      selectBarang: [],
      getdrugs: [],
      getgoods: [],
      dataTransaction: {
        status: '',
        code: '',
        to: '',
        from: '',
        note: '',
        idTransaction: '',
        opnameDate: '',
      },
      input: {
        checked: [],
        totalChecklist: '',
        detail: [],
        barang: [],
      },
      table: {
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 5,
          pageLength: 1,
        },
        search: '',
      },
      headers: [
        {
          text: 'No',
          align: 'start',
          sortable: false,
          value: 'no',
        },

        { text: 'Nama Barang', value: 'name' },
        { text: 'Supplier', value: 'supplier', width: '20%' },
        { text: 'Expired Date', value: 'expDate' },
        { text: 'Batch', value: 'batch' },
        { text: 'Sisa Stok', value: 'residual' },
        { text: 'Sisa Fisik', value: 'realStock' },
        { text: 'Catatan', value: 'reason' },
      ],
      items: [],
    };
  },
  computed: {
    isManager() {
      return this.$store.getters.userLoggedIn.role.some(
        item => item.toLowerCase() === 'kepala unit',
      );
    },
    isLastPage() {
      return this.table.pagination.page === this.table.pagination.pageLength;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getStockById();
      // this.checklistCounter();
      this.getUnitById(); // get unit based user logged in
    });
  },
  methods: {
    async getUnitById() {
      try {
        const response = await axios.get(
          Constant.apiUrl.concat(
            `/master/unit/${this.$store.getters.userLoggedIn.unit_id}`,
          ),
        );
        this.unit = response.data.data.name;
      } catch {
        this.unit = '';
      }
    },
    async approval(status) {
      const payload = {
        to: this.dataTransaction.to,
        from: this.dataTransaction.from,
        action: 'opname',
        code: this.dataTransaction.code,
        note: this.dataTransaction.note,
        detail: [
          {
            status,
            item: this.items.map(item => {
              return {
                id_goods: item.id_goods || null,
                id_drugs: item.id_drugs || null,
                id_stock: item?.id_stock || null,
                id_supplier: item?.id_supplier,
                expiry_date: item.expDate,
                batch_number: item.batch,
                quantity: parseFloat(item.realStock),
                old_quantity: parseFloat(item.residual),
                isCorrection: item.correction,
                reason: item.reason,
              };
            }),
            timestamps: {
              confirmed_at: moment().format(),
              confirmed_by: this.$store.getters.userLoggedIn.id,
              created_at: this.dataTransaction.opnameDate,
            },
          },
        ],
        timestamps: {
          created_at: this.dataTransaction.opnameDate,
          created_by: this.dataTransaction.requester,
        },
      };
      try {
        const response = await axios.post(
          Constant.apiUrl.concat(
            `/transaction/post?id_transaction=${this.dataTransaction.idTransaction}`,
          ),
          payload,
        );
        if (response.status === 200) {
          this.showFillSuccess('Sukses', 'Berhasil menyimpan');
          this.onCloseButtonClick();
        }
      } catch (error) {
        this.showErrorAxios(error);
      }
    },
    getStockById() {
      this.isLoading = true;
      axios
        .get(
          Constant.apiUrl
            .concat('/transaction/get/')
            .concat(localStorage.getItem('opname_id')),
        )
        .then(response => {
          const data = response.data.data.detail.item;
          this.items = data.map((item, index) => {
            return {
              no: index + 1,
              name: item.id_drugs
                ? `${item.id_drugs.detail[0].name} ${item.id_drugs.detail[0].strength} ${item.id_drugs.detail[0].strength_unit} (${item.id_drugs.detail[0].factory})`
                : `${item.id_goods.detail[0].name} (${item.id_goods.detail[0].factory})`,
              id_drugs: item.id_drugs ? item.id_drugs._id : undefined,
              id_goods: item.id_goods ? item.id_goods._id : undefined,
              id_stock: item?.id_stock,
              id_supplier: item?.id_supplier,
              expDate: item.expiry_date,
              supplier: item.supplier || '-',
              batch: item.batch_number,
              residual: item.old_quantity,
              realStock: item.quantity,
              reason: item.reason,
              correction: item.isCorrection,
            };
          });
          this.dataTransaction = {
            note: response.data.data.note,
            to: response.data.data.to._id,
            unitTo: response.data.data.to.name,
            from: response.data.data.from._id,
            idTransaction: response.data.data._id,
            code: response.data.data.code,
            status: response.data.data.detail.status,
            requester: response.data.data.timestamps.created_by._id,
            opnameDate: response.data.data.timestamps.created_at,
          };
          this.input.totalChecklist = this.items.length;
          this.input.checked.push(
            ...this.items.map(item => {
              return {
                correction: item.correction,
              };
            }),
          );
          const arr = this.input.checked;
          const result = arr.filter(item => item.correction === true);
          this.checked.push(...result);
        })
        .catch(error => {
          this.showErrorAxios(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addNewList() {
      this.items.push({
        name: this.input.barang.name,
        id_drugs: this.input.barang.id_drugs,
        id_goods: this.input.barang.id_goods,
        residual: this.input.barang.strength,
      });
      // this.totalPrice();
      // this.afterAdd();
    },

    onCloseButtonClick() {
      this.$emit('close-form');
    },
  },
};
</script>

<style lang="scss" scoped>
.table-content {
  height: 45vh;
  overflow: auto;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(163, 163, 163);
  }
  &::-webkit-scrollbar-thumb {
    width: 3px;
    background-color: black;
  }
}
.grid-display {
  display: grid;
  &.header {
    grid-template-columns: 50px 3fr 1.5fr 1.5fr 1fr 2fr 2fr;
    grid-column-gap: 5px;
    grid-template-rows: 16px;
    padding: 12px 10px;
    height: 40px;
    border-top: 1.5px solid #e0e0e0;
    border-bottom: 1.5px solid #e0e0e0;
    span {
      display: block;
      color: #222222;
      font-weight: 500;
      font-size: 0.9rem;
    }
  }
  &.table-items {
    grid-template-columns: 50px 3fr 1.5fr 1.5fr 1fr 2fr 2fr;
    grid-column-gap: 5px;
    grid-template-rows: auto;
    padding: 0px 10px;
    margin-top: 10px;
    margin-bottom: 6px;
    align-items: flex-start;
    span {
      display: block;
      color: #222222;
      font-weight: 400;
      font-size: 0.9rem;
    }
  }
}
.input {
  max-width: 50%;
}
</style>
