import Constant from '@/const';
import axios from 'axios';

const getStatusPrintLetter = id_registration => {
  try {
    const response = axios.get(
      Constant.apiUrl.concat(`/patient/registration/letter/${id_registration}`),
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export default getStatusPrintLetter;
