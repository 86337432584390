<template>
  <div class="prolanis-bpjs">
    <v-toolbar flat outlined extension-height="100" extended prominent>
      <v-container fluid>
        <v-row align="center">
          <v-col>
            <v-toolbar-title class="font-weight-bold float-left">
              Laporan Pasien PROLANIS BPJS
            </v-toolbar-title>
            <div class="caption grey--text float-left">
              Update: {{ updateDate }}
            </div>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            class="mr-2"
            color="primary"
            @click="exportProlanisReport"
            depressed
          >
            <span>Export</span>
          </v-btn>
          <v-btn
            class="mr-2"
            color="grey"
            to="/reporting/laporan-pelayanan/"
            icon
            text
          >
            <font-awesome-icon icon="home" size="lg" />
          </v-btn>
          <v-btn class="mr-2" color="grey" icon text @click="refresh()">
            <font-awesome-icon icon="sync-alt" size="lg" />
          </v-btn>
          <v-text-field
            class="search"
            background-color="grey lighten-3"
            placeholder="Search"
            v-model="search"
            solo
            hide-details
            flat
            prepend-inner-icon="mdi-magnify"
            single-line
            :search-input.sync="search"
          />
        </v-row>
        <v-row>
          <v-col cols="8" class="text-left mt-16 pt-8 subtitle-1"
            >Menampilkan {{ displayData }} dari
            {{ amountData.length }} data</v-col
          >
          <v-col class="text-left" cols="2">
            <!-- <span class="subtitle-1">Tanggal Terdaftar</span> -->
            <Datepicker
              :show="datePicker"
              :date="date"
              isClearable
              @date-change="handleDateChange"
            />
          </v-col>
          <v-col class="text-left" cols="2">
            <!-- <span class="subtitle-1">Diagnosis</span> -->
            <v-combobox
              placeholder="Pilih Diagnosa"
              :items="diagnose"
              item-text="view"
              @input="fillter()"
              v-model="input.diagnose"
              :loading="diagnoseLoading"
              :search-input.sync="searchDiagnose"
              class="input"
              dense
              @blur="validateDiagnose"
              @click:clear="clearDiagnose()"
              clearable
              outlined
            >
            </v-combobox
          ></v-col>
        </v-row>
      </v-container>
    </v-toolbar>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <template>
              <v-data-table
                :headers="table.headers"
                :items="table.items"
                :items-per-page="5"
                :height="height"
                hide-default-footer
                :page.sync="table.pagination.page"
                :loading="isLoading"
              >
                <template v-slot:item="{ item, index }">
                  <tr class="row-datatable" :key="index">
                    <td class="font-weight-bold text-sm-left">
                      {{
                        (table.pagination.page - 1) *
                          table.pagination.rowsPerPage +
                          index +
                          1
                      }}
                    </td>
                    <td class="font-weight-bold text-sm-left">
                      {{ item.rmNumber }}
                    </td>
                    <td class="font-weight-bold text-sm-left">
                      {{ item.patientName }}
                    </td>
                    <td class="font-weight-bold text-sm-left">
                      {{ item.bpjsNumber }}
                    </td>
                    <td class="font-weight-bold text-sm-left">
                      {{ item.mobileNumber }}
                    </td>
                    <td class="font-weight-bold text-sm-left">
                      {{ item.registDate }}
                    </td>
                    <td class="font-weight-bold text-sm-left">
                      {{ item.address }}
                    </td>
                    <td class="font-weight-bold text-sm-left">
                      {{ item.diagnose }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer color="white">
      <v-container fluid>
        <v-row>
          <v-pagination
            v-model="table.pagination.page"
            :length="itemPages"
            total-visible="15"
            @input="fillter()"
          ></v-pagination>
          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import Constant from '@/const';
import alertMixin from '@/mixin/alertMixin';
import Datepicker from '@/components/SharedComponent/Datepicker/Datepicker.vue';
import Swal from 'sweetalert2';
const _ = require('lodash');
const axios = require('axios');
const moment = require('moment');

export default {
  components: {
    Datepicker,
  },
  mixins: [alertMixin],
  data() {
    return {
      search: '',
      searchDiagnose: '',
      amountData: '',
      displayData: '',
      isLoading: false,
      diagnoseLoading: false,
      itemPages: 0,
      datePicker: false,
      dateFormat: '',
      date: new Date().toISOString().substr(0, 10),
      diagnose: [],
      input: {
        diagnose: '',
      },
      table: {
        pagination: {
          descending: true,
          page: 1,
          rowsPerPage: 5,
          totalItems: null,
        },
        headers: [
          {
            text: 'No',

            sortable: false,
            value: 'number',
          },
          { text: 'No RM', value: 'rmNumber' },
          { text: 'Nama Pasien', value: 'patientName' },
          { text: 'No. BPJS', value: 'bpjsNumber' },
          { text: 'No. HP', value: 'mobileNumber' },
          { text: 'Tanggal Terdaftar', value: 'registDate' },
          { text: 'Alamat', value: 'address' },
          { text: 'Diagnosa', value: 'diagnose' },
        ],
        items: [],
      },
    };
  },

  computed: {
    updateDate: {
      get() {
        return moment()
          .locale('id')
          .format('dddd, DD MMMM YYYY');
      },
    },
    height() {
      let height = 0;
      switch (this.$vuetify.breakpoint.name) {
        case 'md':
          height = '35vh';
          break;
        case 'lg':
          height = '40vh';
          break;
        case 'xl':
          height = '50vh';
          break;
      }
      return height;
    },
  },

  watch: {
    date(val) {
      if (val) {
        this.dateFormat = this.formatDate(this.date);
      }
    },
    searchDiagnose(value) {
      if (value) {
        this.searchDataDiagnose(this);
      }
    },
    search() {
      this.searchDataFunc(this);
    },
  },
  beforeMount() {
    this.date = '';
  },
  mounted() {
    this.$nextTick(() => {
      this.fillter();
      this.getDiagnose();
    });
  },

  methods: {
    searchDataDiagnose: _.debounce(v => v.getDiagnose(v.searchDiagnose), 650),
    searchDataFunc: _.debounce(v => v.fillter(), 650),
    handleDateChange(updatedDate) {
      this.date = updatedDate;
      this.table.pagination.page = 1;
      this.fillter();
    },
    validateDiagnose() {
      if (!this.input.diagnose) {
        this.input.diagnose = '';
      }
    },
    clearDate() {
      this.dateFormat = '';
      this.date = '';
      this.fillter();
    },
    clearDiagnose() {
      this.searchDiagnose = '';
      this.input.diagnose = '';
      this.fillter();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    refresh() {
      this.dateFormat = '';
      this.date = '';
      this.input.diagnose = '';
      this.fillter();
    },
    fillter() {
      this.isLoading = true;
      const diagnose = this.input.diagnose.id;
      const itemCount = 5;
      const date = this.date;
      if (this.search) {
        this.table.pagination.page = 1;
      }
      axios
        .get(
          Constant.apiUrl
            .concat(`/report/patient-prolanis-bpjs`)
            .concat('?diagnosis=')
            .concat(diagnose === null || diagnose === undefined ? '' : diagnose)
            .concat('&register_date=')
            .concat(`&itemCount=5`)
            .concat(`&page=${this.table.pagination.page}`)
            .concat(`&search=${this.search}`)
            .concat(date || ''),
        )
        .then(response => {
          const getDataItem = response.data.data;
          const getAmountItem = response.data;
          if (response.data.length < 5) {
            this.displayData = getAmountItem.length;
          } else {
            this.displayData = '5';
          }
          this.amountData = getAmountItem;
          this.itemPages = Math.ceil(response.data.length / itemCount);
          this.table.items = getDataItem.map(item => {
            return {
              rmNumber: item.medical_record_number,
              patientName: item.social.name,
              bpjsNumber: item.social.assurance.number,
              mobileNumber: item.social.phone_number,
              registDate: moment(item.social.timestamps.created_at).format(
                'DD/MM/YYYY',
              ),
              address: item.social.address.text,
              diagnose: item.checkup.assesment.main_diagnose
                ? item.checkup.assesment.main_diagnose.code +
                  ' - ' +
                  item.checkup.assesment.main_diagnose.diagnose
                : '',
            };
          });
          this.menu = false;
          this.isLoading = false;
        })
        .catch(() => {
          this.table.items = [];
          this.isLoading = false;
          this.menu = false;
        });
    },
    // getItem() {
    //   this.isLoading = true;
    //   const itemCount = 5;

    //   axios
    //     .get(Constant.apiUrl.concat('/report/patient-prolanis-bpjs/'))
    //     .then(response => {
    //       const getDataItem = response.data.data;
    //       const getAmountItem = response.data;
    //       if (response.data.length < 5) {
    //         this.displayData = getAmountItem.length;
    //       } else {
    //         this.displayData = '5';
    //       }
    //       this.amountData = getAmountItem;
    //       this.itemPages = Math.ceil(response.data.length / itemCount);
    //       this.table.items = getDataItem.map(item => {
    //         return {
    //           rmNumber: item.medical_record_number,
    //           patientName: item.social.name,
    //           bpjsNumber: item.social.assurance.number,
    //           mobileNumber: item.social.phone_number,
    //           registDate: moment(item.social.timestamps.created_at).format(
    //             'DD/MM/YYYY',
    //           ),
    //           address: item.social.address.text,
    //           diagnose: item.checkup.assesment.main_diagnose
    //             ? item.checkup.assesment.main_diagnose.code +
    //               ' - ' +
    //               item.checkup.assesment.main_diagnose.diagnose
    //             : '',
    //         };
    //       });
    //       this.isLoading = false;
    //     })
    //     .catch(() => {
    //       this.isLoading = false;
    //       this.table.items = [];
    //     });
    // },
    getDiagnose(keyword) {
      this.diagnoseLoading = true;
      axios
        .get(
          Constant.apiUrl.concat(
            `/master/diagnose/prolanis?page=1&search=${
              !keyword ? '' : keyword
            }&sort=-code`,
          ),
        )
        .then(response => {
          let allDiagnose = response.data.data;
          this.diagnose = allDiagnose.map(diagnose => {
            return {
              id: diagnose._id.toString(),
              code: diagnose.code,
              text: diagnose.description,
              view: diagnose.code + ' - ' + diagnose.description,
            };
          });
          this.diagnoseLoading = false;
        })
        .catch(() => {
          this.diagnoseLoading = false;
        });
    },
    async exportProlanisReport() {
      try {
        const res = this.dateFormat
          ? await axios.get(
              Constant.apiUrl.concat(
                `/report/prolanis_report?startDate=&endDate=&register_date=${this
                  .date || ''}`,
              ),
            )
          : await axios.get(Constant.apiUrl.concat(`/report/prolanis_report`));
        if (res.status === 200) {
          this.showFillSuccess('Export Berhasil');
          const { url } = res.config;
          window.open(url, '_blank');
        }
      } catch (error) {
        if (error) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat mengexport dokumen',
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  max-width: 30%;
}
</style>
