var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.stateResult === 'request' &&
      (_vm.stateIsFinished === 'not_scheduled' ||
        _vm.stateIsFinished === 'scheduled' ||
        _vm.getLabReqById.isInputed === false)
  )?_c('div',[_c('h3',{staticClass:"d-flex justify-center align-center empty-text-state"},[_vm._v(" Belum ada hasil pemeriksaan laboratorium ")])]):_c('div',{staticClass:"wrap-result pe-10"},[[_c('v-row',{attrs:{"no-gutters":"","align":"baseline"}},[_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"4"}},[_c('v-row',{attrs:{"no-gutters":"","align":"baseline"}},[_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"6"}},[_c('label',{staticClass:"required"},[_vm._v(" Petugas Laboratorium ")])]),_c('v-col',{staticClass:"d-flex justify-start align-center",attrs:{"cols":"6"}},[_c('v-form',{ref:"formResult"},[_c('v-autocomplete',{attrs:{"id":"Dokter Laboratorium","item-text":"name","placeholder":"Pilih petugas","items":_vm.staff,"item-value":staff => staff.id,"append-icon":_vm.stateResult === 'request' || _vm.labResult.isFinished === true
                    ? ''
                    : 'mdi-chevron-down',"dense":"","readonly":_vm.stateResult === 'request' || _vm.labResult.isFinished === true,"rules":[v => !!v || 'Wajib Diisi']},on:{"input":function($event){return _vm.handleChangeStaff($event)}},model:{value:(_vm.inputResultStaff),callback:function ($$v) {_vm.inputResultStaff=$$v},expression:"inputResultStaff"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"8"}})],1),_c('v-tabs',{staticClass:"tab-header",model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.labResultTestItem),function({ category },index){return _c('v-tab',{key:index,staticClass:"text-none tab-header__tab"},[_vm._v(_vm._s(category))])}),1),_c('v-tabs-items',{staticClass:"pt-6",model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_vm._l((_vm.labResultTestItem),function({ testItem },index2){return _c('span',{key:index2},[_c('v-tab-item',{staticClass:"text-start",attrs:{"transition":false}},_vm._l((testItem),function(item,index3){return _c('span',{key:index3},[_c('label',[_vm._v(_vm._s(item.name))]),_c('v-data-table',{staticClass:"mb-4 mt-2 table-class",attrs:{"single-expand":"","headers":_vm.table.headers,"items":item.result,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:`item.result`,fn:function({ index }){return [(
                    _vm.stateResult === 'request' || _vm.labResult.isFinished === true
                  )?_c('span',[_vm._v(_vm._s(item.result[index].result))]):_c('span',[_c('v-text-field',{staticStyle:{"width":"80%"},attrs:{"dense":"","name":"inputResultItem"},on:{"input":function($event){return _vm.handleChangeInputResultItem(index2, index)}},model:{value:(item.result[index].result),callback:function ($$v) {_vm.$set(item.result[index], "result", $$v)},expression:"item.result[index].result"}})],1)]}}],null,true)})],1)}),0)],1)}),_c('v-row',{staticClass:"text-start",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-4",attrs:{"cols":"6"}},[_c('h4',{staticClass:"pb-2 label-text-area"},[_vm._v(" Catatan/Kesan ")]),_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","value":"Catatan/Kesan","readonly":_vm.stateResult === 'request' || _vm.labResult.isFinished === true,"rules":[
              v => (v || '').length < 3000 || 'Maksimal 3000 karakter',
            ],"maxlength":"3000"},on:{"input":function($event){return _vm.handleChangeInputResultNotes($event)}},model:{value:(_vm.inputResultNotes),callback:function ($$v) {_vm.inputResultNotes=$$v},expression:"inputResultNotes"}})],1),_c('v-col',{staticClass:"pl-4",attrs:{"cols":"6"}},[_c('h4',{staticClass:"pb-2 label-text-area"},[_vm._v("Rekomendasi")]),_c('v-textarea',{attrs:{"outlined":"","name":"input-7-4","value":"Rekomendasi","readonly":_vm.stateResult === 'request' || _vm.labResult.isFinished === true,"rules":[
              v => (v || '').length < 3000 || 'Maksimal 3000 karakter',
            ],"maxlength":"3000"},on:{"input":function($event){return _vm.handleChangeInputResultRecommendation($event)}},model:{value:(_vm.inputResultRecommendation),callback:function ($$v) {_vm.inputResultRecommendation=$$v},expression:"inputResultRecommendation"}})],1)],1)],2)],_c('v-snackbar',{staticClass:"pb-15",attrs:{"absolute":"","light":"","content-class":"font-weight-medium pr-0","value":_vm.snackbar.show,"elevation":"0","color":_vm.snackbar.color,"height":"15"}},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('span',{staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c('v-btn',{staticStyle:{"color":"white"},attrs:{"text":"","fab":"","small":"","right":""},on:{"click":function($event){_vm.snackbar.show = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }