<template>
  <v-main>
    <dashboard-queue-header :loading="loading" :clinic="clinicName" />
    <section class="text-left">
      <v-row>
        <v-col cols="8" class="pa-10">
          <div
            :class="{
              'grid-container': publicQueue.length <= 4,
              'grid-container-6': publicQueue.length > 4,
            }"
          >
            <div class="grid-item" v-for="item in publicQueue" :key="item._id">
              <div
                class="queue-card__room"
                :style="{
                  'background-color': `${item.color}`,
                }"
              >
                {{ item?.room }} - {{ item.id_unit?.name || '' }}
              </div>
              <div
                :style="{
                  color: `${item.color}`,
                }"
                :class="{
                  'queue-card__code queue-card__normal_font': item.isNormalFont,
                  'queue-card__code queue-card__small_font': !item.isNormalFont,
                }"
              >
                <label>
                  {{ item.queueNormal?.[0]?.queue_code || '-' }}
                </label>
              </div>
              <div class="queue-card__poly">
                {{ item.nextQueue.slice(0, 4).join(', ') }}
              </div>
            </div>
          </div>
        </v-col>
        <v-col class="pa-10">
          <v-card flat class="bordered-card pass-queue-card">
            <v-card-title
              @click="decrementNumbersOfTabOpen"
              class="mb-4 px-0 d-flex justify-center"
              style="background-color: #f0f8fe; font-size: 1.3vw; color: #404040 "
            >
              ANTREAN DILEWATI
            </v-card-title>
            <div class="container-limit">
              <div
                class="list-container"
                :class="{ animate: isOverflow }"
                id="list-container"
              >
                <div
                  v-for="(item, index) in missedPatientsForPublicView"
                  :key="`missed-queue-${index}`"
                >
                  <div class="title">
                    <span class="text-uppercase">{{ item.poly }}</span>
                    <span>{{ item.doctor }}</span>
                  </div>
                  <div class="queue-list">
                    <span
                      v-for="(code, idx) in item.queueCodeList"
                      :key="`code-${idx}`"
                      class="primary--text"
                      >{{ code }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </section>
    <audio ref="notification">
      <source src="../../../../../assets/sound/bell.mp3" type="audio/mpeg" />
    </audio>
  </v-main>
</template>

<script>
import DashboardQueueForPatientScript from './DashboardQueueForPatient.js';

export default DashboardQueueForPatientScript;
</script>

<style lang="scss" scoped>
$primary-text-color: #616161;

.grid-container {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 5% 2%;
}

.grid-container-6 {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 5% 2%;
}

.grid-item {
  min-width: 100%;
  background-color: #ffffff;
  border: 0.1rem solid #e0e0e0;
  border-radius: 10px;
  height: 100%;
}

.queue-card {
  &__room {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 20px;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    color: #ffff;
  }
  &__code {
    font-weight: 600;
    color: #137bc0;
    text-align: center;
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__normal_font {
    font-size: 90px;
  }
  &__small_font {
    font-size: 70px;
  }
  &__poly {
    color: #404040;
    font-size: 1.6vw;
    font-weight: 500;
    text-align: center;
  }
}

section:nth-child(2) {
  height: 89vh;
  background-color: #fafafa;

  .bordered-card {
    border: 2px solid #e0e0e0;
    border-radius: 9px;
    background-color: white;
  }

  .poly-card {
    height: 4.5vw;
    padding: 1vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    text-align: start;
    color: $primary-text-color;
    font-size: 1.1vw;
    align-items: center;
  }

  .pass-queue-card {
    height: 80vh;
    color: $primary-text-color;
    text-align: start;
    font-weight: 500;

    .container-limit {
      max-height: 80vh;
      padding: 0 1vw;
      overflow: hidden;

      .animate {
        animation: loop-scroll 10s linear infinite;

        @keyframes loop-scroll {
          0% {
            transform: translate3d(0, 65vh, 0);
          }
          100% {
            transform: translate3d(0, -110%, 0);
          }
        }
      }

      .list-container {
        overflow-y: auto;
        max-height: 80vh;

        .title {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #fafafa;
          padding: 0.4vw;

          span:first-child {
            font-size: 1.1vw;
            color: #404040;
          }
          span:last-child {
            font-size: 0.9vw;
            color: #9e9e9e;
          }
        }

        .queue-list {
          display: grid;
          margin-top: 0.7vw;
          margin-bottom: 2.5vw;
          gap: 1.2vw;
          font-size: 1.1vw;
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}
</style>
