import { getReturnStatus } from '@/fetchApi/MasterData/Others.js';
import { getAllProcedure } from '@/fetchApi/MasterData/Procedure';
import { getAllDiagnose } from '@/fetchApi/MasterData/Diagnose';

const state = {
  diagnoses: [{ diagnose: '', icd10: '' }],
  therapies: [{ therapy: '', icd9: '' }],
  intervention: [{ intervention: '', icd9: [''] }],
  return_status: '',
  other_inspection: '',
  items: {
    diagnose: {
      loading: false,
      items: [],
    },
    procedure: {
      loading: false,
      items: [],
    },
    return_status: {
      loading: false,
      items: [],
    },
  },
};

const getters = {
  getPlan: state => state,
  getItems: state => state.items,
};

const mutations = {
  setItems(state, payload) {
    const { label, value, loading } = payload;
    state.items[label] = {
      ...state[label],
      items: value,
      loading: loading,
    };
  },
  addDiagnose(state) {
    state.diagnoses.push({ diagnose: '', icd10: '' });
  },
  deleteDiagnose(state, payload) {
    state.diagnoses.splice(payload, 1);
  },
  addTherapies(state) {
    state.therapies.push({ therapy: '', icd9: '' });
  },
  deleteTherapies(state, payload) {
    state.therapies.splice(payload, 1);
  },
  addIntervention(state) {
    state.intervention.push({ intervention: '', icd9: [''] });
  },
  deleteIntervention(state, payload) {
    state.intervention.splice(payload, 1);
  },
  addICD9(state, payload) {
    state.intervention[payload].icd9.push('');
  },
  deleteICD9(state, payload) {
    state.intervention[payload.index].icd9.splice(payload.index2, 1);
  },
  setPlanning(state, payload) {
    Object.assign(state, { ...payload });
  },
  clearPlanning(state) {
    Object.assign(state, {
      diagnoses: [{ diagnose: '', icd10: '' }],
      therapies: [{ therapy: '', icd9: '' }],
      intervention: [{ intervention: '', icd9: [''] }],
      return_status: '',
      other_inspection: '',
    });
  },
};

const actions = {
  async resolveGetReturnStatus({ commit }) {
    commit('setItems', {
      label: 'return_status',
      value: [],
      loading: true,
    });
    try {
      const response = await getReturnStatus();
      let data = response.data.data.map(v => {
        return {
          code: v.code,
          name: v.description,
        };
      });
      data.push({
        code: '2',
        name: 'Rawat Inap',
      });
      commit('setItems', {
        label: 'return_status',
        value: data,
        loading: false,
      });
    } catch (error) {
      commit('setItems', {
        label: 'return_status',
        value: [],
        loading: false,
      });
    }
  },

  async resolveGetProcedure({ commit }, keyword) {
    const params = {
      halaman: 1,
      itemCount: 20,
      sortBy: '',
      keyword: keyword || '',
    };
    commit('setItems', {
      label: 'procedure',
      value: [],
      loading: true,
    });
    try {
      const response = await getAllProcedure(params);
      const data = response.data.map(v => {
        return {
          id: v._id,
          code: v.code,
          text: v.description,
          displayName: `${v.code} ${v.description}`,
        };
      });
      commit('setItems', {
        label: 'procedure',
        value: data,
        loading: false,
      });
    } catch (error) {
      commit('setItems', {
        label: 'procedure',
        value: [],
        loading: false,
      });
    }
  },

  async resolveGetDiagnose({ state, commit }, keyword) {
    const params = {
      page: 1,
      itemCount: 20,
      sort: '',
      search: keyword || '',
    };
    commit('setItems', {
      label: 'diagnose',
      value: state.items.diagnose.items,
      loading: true,
    });
    try {
      const response = await getAllDiagnose(params);
      const resData = response.data.map(v => {
        return {
          id: v._id,
          code: v.code,
          text: v.description,
          displayName: `${v.code} ${v.description}`,
        };
      });
      const existingItems = state.items.diagnose.items;
      const newData = resData.filter(
        newItem =>
          !existingItems.some(
            existingItem => existingItem.code === newItem.code,
          ),
      );
      const data = existingItems.concat(newData);
      commit('setItems', {
        label: 'diagnose',
        value: data,
        loading: false,
      });
    } catch (error) {
      if (error.status === 500) {
        commit('setItems', {
          label: 'diagnose',
          value: [],
          loading: false,
        });
      } else {
        commit('setItems', {
          label: 'diagnose',
          value: state.items.diagnose.items,
          loading: false,
        });
        return error;
      }
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
