import JsPDF from 'jspdf';
import Constant from '../const';
import physicalExamination from '../helper/physicalExamination';

const moment = require('moment-timezone');

export default {
  methods: {
    generateCpptPrintData(data, patient) {
      const doc = new JsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: 'a4',
      });

      const leftMargin = 0.4;

      // document title
      doc.setFontSize(12);
      doc.setTextColor('#404040');
      doc.setFont('Helvetica', 'bold');
      this.pdfTextPrint(
        doc,
        'CATATAN PERKEMBANGAN PASIEN TERINTEGRASI (CPPT) RAWAT JALAN',
        0.3,
      );
      this.pdfTextPrint(
        doc,
        `Tanggal Cetak : ${moment().format('DD MMMM YYYY')}`,
        0.55,
      );

      // meta data table
      const metadata = [
        [
          ['No RM', ':', patient.rmNumber],
          ['Nama Pasien', ':', patient.name],
          [
            'Tempat/Tgl Lahir',
            ':',
            `${patient.birth.place}, ${moment(
              patient.birth.date,
              'YYYY-MM-DD',
            ).format('DD MMMM YYYY')} (${patient.age} Tahun)`,
          ],
        ],
        [
          ['Jenis Kelamin', ':', patient.gender ? 'Laki-laki' : 'Perempuan'],
          ['Alamat', ':', this.generateAddress(patient?.address) || '-'],
        ],
        [
          ['Riwayat Alergi', ':', patient.allergy?.join(', ') || '-'],
          ['Riwayat Penyakit', ':', patient.disease_history?.join(', ') || '-'],
        ],
      ];
      let finalY = 0;
      metadata.forEach((item, index) => {
        doc.autoTable({
          body: item,
          startY: 0.8,
          style: {
            halign: 'left',
          },
          columnStyles: {
            0: {
              textColor: '#404040',
              cellWidth: 1.2,
              cellPadding: 0.04,
              fontSize: 9,
            },
            1: {
              textColor: '#404040',
              cellWidth: 0.2,
              cellPadding: 0.04,
              fontSize: 9,
            },
            2: {
              textColor: '#404040',
              cellWidth: index === 2 ? 1.7 : 2.3,
              cellPadding: 0.04,
              fontSize: 9,
            },
          },
          margin: {
            left: leftMargin + index * 3.9,
            right: 0.5,
          },
          theme: 'plain',
        });
        finalY =
          finalY > doc.autoTable.previous.finalY
            ? finalY
            : doc.autoTable.previous.finalY;
      });

      const metaDataFinalY = finalY;
      let body = [];
      data.forEach((item, index) => {
        body.push([
          [
            {
              content: index + 1,
              rowSpan: 6,
              styles: {
                lineWidth: {
                  top: 0.0001,
                },
                lineColor: '#c2c2c2',
              },
            },
            {
              content: `${item.dateSplit[0]}, ${item.dateSplit[1]}`,
              rowSpan: 6,
              styles: {
                lineWidth: {
                  top: 0.0001,
                },
                lineColor: '#c2c2c2',
              },
            },
            {
              content: item.unit,
              rowSpan: 6,
              styles: {
                lineWidth: {
                  top: 0.0001,
                },
                lineColor: '#c2c2c2',
              },
            },
            {
              content: item?.doctor || '-',
              rowSpan: 6,
              styles: {
                lineWidth: {
                  top: 0.0001,
                },
                lineColor: '#c2c2c2',
              },
            },
            {
              content: 'Anamnesis Perawat',
              styles: {
                fontStyle: 'bold',
                cellPadding: {
                  top: 0.1,
                  bottom: 0.01,
                  left: 0.1,
                  right: 0.1,
                },
                lineWidth: {
                  top: 0.0001,
                },
                lineColor: '#c2c2c2',
              },
            },
            {
              content: 'TTV',
              styles: {
                fontStyle: 'bold',
                valign: 'bottom',
                cellPadding: {
                  top: 0.04,
                  bottom: 0.01,
                  left: 0.1,
                  right: 0.1,
                },
                lineWidth: {
                  top: 0.0001,
                },
                lineColor: '#c2c2c2',
              },
            },
            {
              content: 'Diagnosa Utama',
              styles: {
                fontStyle: 'bold',
                valign: 'bottom',
                cellPadding: {
                  top: 0.04,
                  bottom: 0.01,
                  left: 0.1,
                  right: 0.1,
                },
                lineWidth: {
                  top: 0.0001,
                },
                lineColor: '#c2c2c2',
              },
            },
            {
              content: 'Rencana Pelayanan/KIE',
              styles: {
                fontStyle: 'bold',
                valign: 'bottom',
                lineWidth: {
                  top: 0.0001,
                },
                lineColor: '#c2c2c2',
              },
            },
          ],
          [
            {
              content: item?.subjective?.nurse_anamnesis?.join(', ') || '-',
              rowSpan: 2,
              styles: {
                cellPadding: {
                  top: 0.01,
                  bottom: 0.01,
                  left: 0.1,
                  right: 0.1,
                },
              },
            },
            {
              styles: {
                cellPadding: {
                  top: 0.01,
                  bottom: 0.15,
                  left: 0.1,
                  right: 0.1,
                },
              },
              content: this.generateTtv(item.objective) || '-',
            },
            {
              content: item.diagnose?.main_diagnose || '-',
              styles: {
                cellPadding: {
                  top: 0.01,
                  bottom: 0.04,
                  left: 0.1,
                  right: 0.1,
                },
              },
            },
            {
              content: item.planning.join(', ') || '-',
              styles: {
                cellPadding: {
                  top: 0.01,
                  bottom: 0.04,
                  left: 0.1,
                  right: 0.1,
                },
              },
            },
          ],
          [
            {
              content: 'Pemeriksaan Fisik',
              styles: {
                fontStyle: 'bold',
                valign: 'bottom',
                cellPadding: {
                  top: 0.04,
                  bottom: 0.01,
                  left: 0.1,
                  right: 0.1,
                },
              },
            },
            {
              content: 'Diagnosa Sekunder',
              styles: {
                fontStyle: 'bold',
                cellPadding: {
                  top: 0.04,
                  bottom: 0.01,
                  left: 0.1,
                  right: 0.1,
                },
              },
            },
            {
              content: 'Tindakan',
              styles: {
                fontStyle: 'bold',
                valign: 'bottom',
              },
            },
          ],
          [
            {
              content: 'Anamnesis Dokter',
              styles: {
                fontStyle: 'bold',
                valign: 'bottom',
                cellPadding: {
                  top: 0.04,
                  bottom: 0.01,
                  left: 0.1,
                  right: 0.1,
                },
              },
            },
            {
              content: this.generatePhysical(item.physical) || '-',
              styles: {
                cellPadding: {
                  top: 0.01,
                  bottom: 0.15,
                  left: 0.1,
                  right: 0.1,
                },
              },
            },
            {
              content:
                item.diagnose?.other_diagnose?.map(i => `• ${i}`).join('\n') ||
                '-',
              styles: {
                cellPadding: {
                  top: 0.01,
                  bottom: 0.1,
                  left: 0.1,
                  right: 0.1,
                },

                lineColor: '#c2c2c2',
              },
              rowSpan: 3,
            },
            {
              content: item.intercons?.join(', ') || '-',
              styles: {
                cellPadding: {
                  top: 0.01,
                  bottom: 0.04,
                  left: 0.1,
                  right: 0.1,
                },
              },
            },
          ],
          [
            {
              content: item?.subjective?.doctor_anamnesis?.join(', ') || '-',
              rowSpan: 2,
              styles: {
                cellPadding: {
                  top: 0.01,
                  bottom: 0.1,
                  left: 0.1,
                  right: 0.1,
                },

                lineColor: '#c2c2c2',
              },
            },
            {
              content: 'Penunjang',
              styles: {
                fontStyle: 'bold',
                valign: 'bottom',
                cellPadding: {
                  top: 0.04,
                  bottom: 0.01,
                  left: 0.1,
                  right: 0.1,
                },
              },
            },
            {
              content: 'Resep',
              styles: {
                fontStyle: 'bold',
                valign: 'bottom',
              },
            },
          ],
          [
            {
              content: this.generatePenunjang(item) || '-',
              styles: {
                cellPadding: {
                  top: 0.01,
                  bottom: 0.1,
                  left: 0.1,
                  right: 0.1,
                },

                lineColor: '#c2c2c2',
              },
            },
            {
              styles: {
                cellPadding: {
                  top: 0.01,
                  bottom: 0.1,
                  left: 0.1,
                  right: 0.1,
                },

                lineColor: '#c2c2c2',
              },
              content:
                item.recipe
                  ?.map(i => {
                    if (i.drugs) {
                      return `• (Racik ${i.usage}, ${i.quantity} ${
                        i.packaging
                      })\n${i.drugs
                        .map(
                          d =>
                            `${d.name} ${d.dosage} ${d.unit_dosage} ${d.preparation}`,
                        )
                        .join(', ')}\n`;
                    } else {
                      return `• (Non Racik) ${i.name} ${i.dosage} ${i.unit_dosage} ${i.preparation}, ${i.usage}, ${i.quantity} ${i.packaging}\n`;
                    }
                  })
                  .join('') || '-',
            },
          ],
        ]);
      });

      doc.autoTable({
        head: [
          [
            'No',
            'Tgl Kunjungan',
            'Poli',
            'Dokter',
            'Data Subyektif',
            'Data Obyektif',
            'Diagnosa',
            'Rencana Asuhan (Planning)',
          ],
        ],
        body: body.flat(),
        columnStyles: {
          0: {
            cellWidth: 0.5,
            cellPadding: {
              top: 0.04,
              bottom: 0.04,
              left: 0.1,
              right: 0.1,
            },
            valign: 'center',
            textColor: '#404040',
            fontSize: 7,
          },
          1: {
            textColor: '#404040',
            cellWidth: 1.4,
            valign: 'center',
            cellPadding: {
              top: 0.04,
              bottom: 0.04,
              left: 0.1,
              right: 0.1,
            },
            fontSize: 7,
          },
          2: {
            textColor: '#404040',
            cellWidth: 0.8,
            valign: 'center',
            cellPadding: {
              top: 0.04,
              bottom: 0.04,
              left: 0.1,
              right: 0.1,
            },
            fontSize: 7,
          },
          3: {
            textColor: '#404040',
            cellWidth: 1,
            valign: 'center',
            cellPadding: {
              top: 0.04,
              bottom: 0.04,
              left: 0.1,
              right: 0.1,
            },
            fontSize: 7,
          },
          4: {
            textColor: '#404040',
            cellWidth: 1.7,
            cellPadding: {
              top: 0.04,
              bottom: 0.04,
              left: 0.1,
              right: 0.1,
            },
            fontSize: 7,
          },
          5: {
            textColor: '#404040',
            cellWidth: 2,
            cellPadding: {
              top: 0.04,
              bottom: 0.04,
              left: 0.1,
              right: 0.1,
            },
            fontSize: 7,
          },
          6: {
            textColor: '#404040',
            cellWidth: 1.5,
            cellPadding: {
              top: 0.04,
              bottom: 0.04,
              left: 0.1,
              right: 0.1,
            },
            fontSize: 7,
          },
          7: {
            textColor: '#404040',
            cellWidth: 2,
            cellPadding: {
              top: 0.04,
              bottom: 0.04,
              left: 0.1,
              right: 0.1,
            },
            fontSize: 7,
          },
        },
        startY: metaDataFinalY + 0.15,
        styles: {
          fontSize: 7,
          fillColor: 'white',
          textColor: '#757575',
        },
        headStyles: {
          fillColor: '#fafafa',
          textColor: '#757575',
          cellPadding: 0.1,
          halign: 'left',
        },
        margin: {
          left: leftMargin,
          right: leftMargin,
          bottom: 0.8,
        },
        theme: 'plain',
      });

      // render footer
      const clinic = Constant.client.find(
        item =>
          item.name.toLowerCase() ===
          this.$store.getters.userLoggedIn.clinic.name.toLowerCase(),
      );
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 0; i < +pageCount; i++) {
        doc.setPage(i + 1);
        doc.text(`${i + 1}`, 11, 8); // page number
        doc.setFont('Helvetica', 'bold');
        doc.setFontSize(10);
        if (clinic.cppt.footerCppt) {
          doc.addImage(clinic.cppt.footerCppt, 'PNG', 0.3, 7, 9, 0.9);
        } else {
          doc.text(clinic.cppt.text.text, clinic.cppt.text.x, 7.93);
          // clinic logo
          doc.addImage(
            clinic.leftLogo,
            'PNG',
            clinic.cppt.image.x,
            clinic.cppt.image.y,
            clinic.cppt.image.width,
            clinic.cppt.image.height,
          );
        }
      }

      return doc;
    },
    generatePenunjang(data) {
      let result = [];

      if (data.other) {
        if (data.other.laboratory?.trim()) {
          result.push(`Hasil Lab: ${data.other.laboratory.trim()}`);
        }
        if (data.other.ekg?.trim()) {
          result.push(`Hasil EKG: ${data.other.ekg.trim()}`);
        }
        if (data.other.radiology?.trim()) {
          result.push(`Hasil Radiologi: ${data.other.radiology.trim()}`);
        }
        if (data.other.ctg?.trim()) {
          result.push(`Pemeriksaan Leopold: ${data.other.ctg.trim()}`);
        }
        if (data.other.other?.trim()) {
          result.push(`Catatan Lain-lain: ${data.other.other.trim()}`);
        }
        return result.join(', ');
      } else {
        if (data.others?.laboratory?.length) {
          result.push(
            `Hasil Lab: ${data.others.laboratory
              .map(i => i.result)
              .join(', ')}`,
          );
        }
        if (data.others?.ekg?.length) {
          result.push(
            `Hasil EKG: ${data.others.ekg.map(i => i.result).join(', ')}`,
          );
        }
        if (data.others?.radiology?.length) {
          result.push(
            `Hasil Radiologi: ${data.others.radiology
              .map(i => i.result)
              .join(', ')}`,
          );
        }
        if (data.others?.other?.length) {
          result.push(
            `Catatan Lain-lain: ${data.others.other
              .map(i => i.result)
              .join(', ')}`,
          );
        }
        return result.join(', ');
      }
    },
    generateTtv(data) {
      let result = [];
      const awareness = ['Compos Mentis', 'Somnolence', 'Sopor', 'Coma'];

      if (data.sistol && data.diastol) {
        result.push(`TD: ${data.sistol}/${data.diastol} mmhg`);
      }
      if (data.heart_rate) {
        result.push(`Nadi: ${data.heart_rate}x/mnt`);
      }
      if (data.weight) {
        result.push(`BB: ${data.weight} kg`);
      }
      if (data.height) {
        result.push(`BB: ${data.height} cm`);
      }
      if (data.belly_circumference) {
        result.push(`Lingkar Perut: ${data.belly_circumference} cm`);
      }
      if (data.respiration_rate) {
        result.push(`RR: ${data.respiration_rate}x/mnt`);
      }
      if (data.saturation) {
        result.push(`Saturasi: ${data.saturation}%`);
      }
      if (data.temperature) {
        result.push(`Suhu Tubuh: ${data.temperature} C`);
      }
      if (data.awarness) {
        result.push(`Kesadaran: ${awareness[+data.awarness - 1]}`);
      }
      if (+data.djj) {
        result.push(`DJJ: ${data.djj} x/menit `);
      }

      return result.join(', ') || '-';
    },
    generatePhysical(data) {
      let result = [];

      physicalExamination.forEach(i => {
        if (data?.[`${i.respond}`]?.trim())
          result.push(`${i.label}: ${data[`${i.respond}`].trim()}`);
      });
      return result.join(', ');
    },
    generateAddress(data) {
      let result = [];

      if (data.text?.trim()) {
        result.push(data.text);
      }
      if (data.sub_district?.trim()) {
        result.push(data.sub_district);
      }
      if (data.province?.trim()) {
        result.push(data.province);
      }
      if (data.district?.trim()) {
        result.push(data.district);
      }
      if (data.city?.trim()) {
        result.push(data.city);
      }

      return result.join(', ');
    },
  },
};
